import { Nothing, isNothing } from 'nothing-mock'

export const isBrowser = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)
export const win = isBrowser ? window : Nothing
export const doc = isBrowser ? document : Nothing
export const nav = isBrowser ? navigator : Nothing
export const isValueExists = <V>(variable: V) => !isNothing(variable)
