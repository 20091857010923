import React, { FC } from 'react'

import { TextFieldProps } from '../moleculeTypes'

import { Input } from 'components/molecules/Input'
import { TextArea } from 'components/molecules/TextArea'

// TODO: перенести сюда и объединить логику Input и TextArea. Убрать TextFieldWrapper из-за его сложности
export const TextField: FC<Partial<TextFieldProps>> = ({
  isTextArea,
  isAutoResize,
  rows,
  label,
  tooltip,
  disabled,
  readOnly,
  placeholder,
  placeholderChar,
  type,
  hasError,
  value,
  mask,
  isHidden,
  size,
  isClearable,
  onClear,
  onFocus,
  onBlur,
  onClick,
  clearThreshold,
  showGuide,
  showMask,
  icon,
  endIcon,
  iconColor,
  hasControl,
  hasDropdown,
  asPassword,
  onPasswordShow,
  isVisible,
  componentRef,
  children,
  controlChildren,
  ...rest
}) => {
  const textFieldsProps = {
    label,
    tooltip,
    disabled,
    readOnly,
    placeholder,
    hasError,
    value,
    size,
    isClearable,
    onClear,
    clearThreshold,
    onFocus,
    onBlur,
    onClick,
    children,
    hasDropdown,
    hasControl,
    ...rest,
  }

  const inputProps = {
    placeholderChar,
    type,
    mask,
    isHidden,
    showGuide,
    showMask,
    icon,
    endIcon,
    iconColor,
    asPassword,
    onPasswordShow,
    isVisible,
    controlChildren,
    componentRef: componentRef as React.Ref<HTMLInputElement>,
    ...textFieldsProps,
  }

  const textAreaProps = { isAutoResize, rows, componentRef, ...textFieldsProps }

  return isTextArea ? <TextArea {...textAreaProps} /> : <Input {...inputProps} />
}

TextField.defaultProps = {
  variant: 'default',
  isTextArea: false,
  isAutoResize: false,
  rows: 3,
  label: '',
  tooltip: '',
  disabled: false,
  placeholder: '',
  type: 'text',
  hasError: false,
  readOnly: false,
  mask: null,
  isHidden: false,
  size: 'lg',
  isClearable: false,
  clearThreshold: 0,
  showGuide: false,
  placeholderChar: '_',
  showMask: false,
  hasControl: false,
  isDropdownOpened: false,
  componentRef: null,
  icon: null,
  endIcon: null,
  iconColor: '',
  onClear: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onClick: () => {},
}
