import styled from 'styled-components'

import { theme } from 'theme'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: ${theme.z_indexes.med};

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 420px;
    right: 32px;
    bottom: 32px;
  }
`

export const Styled = { Wrapper }
