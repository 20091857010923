import React, { FC } from 'react'

import { Styled } from './styled'

import { theme } from 'theme'
import { FlexProps } from 'components/atoms/Primitives/Flex/Flex'

export interface SkeletonBodyProps extends FlexProps {
  filled?: boolean
}

const SkeletonBody: FC<SkeletonBodyProps> = ({ children, filled, ...rest }) => {
  return (
    <Styled.Wrapper filled={filled} {...rest}>
      {children}
    </Styled.Wrapper>
  )
}

export interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  height?: string | number
  radius?: string | number
}

const SkeletonRect: FC<SkeletonProps> = ({ height, radius, ...rest }) => {
  return <Styled.Rectangle height={height} radius={radius} {...rest} />
}
const SkeletonCir: FC<Omit<SkeletonProps, 'radius'>> = ({ height, ...rest }) => {
  return <Styled.Round height={height} {...rest} />
}

SkeletonRect.defaultProps = {
  height: theme.spacings.xs,
  radius: 8,
}
SkeletonCir.defaultProps = {
  height: theme.spacings.xs,
}

export { SkeletonBody, SkeletonRect, SkeletonCir }
