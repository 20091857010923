import styled, { css } from 'styled-components'

import {
  getInputPaddingTop,
  getTextAreaPaddingTop,
  getInputBorderColor,
  getInputHeight,
  getIconWidth,
  wrapperPaddings,
} from './utils'

import { getTextareaHeight, InputWrapperProps } from 'components/molecules/TextFieldWrapper/utils'
import { Icon } from 'components/atoms/Icon'
import { theme } from 'theme'

const borderRadiusMixin = css<{ isLabelInside: boolean }>`
  border-radius: ${theme.radiuses.lg}px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    border-radius: ${({ isLabelInside }) => isLabelInside && `${theme.radiuses.xl}px`};
  }
`

const Wrapper = styled.div<{
  isHidden: boolean
  disabled: boolean
  readOnly: boolean
  variant: 'default' | 'privateBanking' | 'premiumBanking' | 'msbAccount'
}>`
  position: relative;
  flex-direction: ${({ isHidden }) => (isHidden ? null : 'column')};
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  -webkit-tap-highlight-color: transparent;
  opacity: ${({ disabled, readOnly, variant }) =>
    variant == 'privateBanking' && (disabled || readOnly) && theme.opacities.op32 / 100};
`

const StartIcon = styled(Icon)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: ${theme.z_indexes.basic};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${getIconWidth()}px;
  pointer-events: none;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: ${({ size }) => getIconWidth(size)}px;
    pointer-events: auto;
  }
`

export const EndIcon = styled(StartIcon)`
  right: 0;
  left: auto;
  width: ${getIconWidth('md')}px;
  position: absolute;
`

const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  background-color: ${({ disabled, readOnly, variant }) =>
    variant == 'privateBanking'
      ? 'transparent'
      : disabled || readOnly
      ? variant == 'premiumBanking'
        ? theme.colors.neutral.g800
      : theme.colors.neutral.g700
      : theme.colors.neutral.g800};
  border: ${theme.borders.basic} transparent;
  border-color: ${getInputBorderColor(false)};
  box-sizing: border-box;
  transition: border-color ${theme.transitions.basic};
  -webkit-tap-highlight-color: transparent;
  ${borderRadiusMixin};

  & .scrollbar-container {
    ${borderRadiusMixin};
  }

  :hover {
    border-color: ${getInputBorderColor(true)};
  }

  :focus-within {
    border-color: ${({ hasError, variant }) =>
      hasError 
      ? theme.colors.orange.primary
      : variant == 'premiumBanking'
        ? theme.colors.emerald.primary
      :
      theme.colors.blue.primary};
  }

  textarea {
    min-height: ${getTextareaHeight(true)}px;
    padding-top: ${getTextAreaPaddingTop(true)}px;
    padding-bottom: ${theme.spacings.xs4}px;
    overflow: hidden;
    resize: none;

    @media (min-width: ${theme.breakpoints.tablet}px) {
      min-height: ${getTextareaHeight(false)}px;
      padding-top: ${getTextAreaPaddingTop(false)}px;
      padding-bottom: ${theme.spacings.xs3}px;
    }
  }

  input {
    height: ${getInputHeight(true)}px;
    padding-top: ${getInputPaddingTop(true)}px;
    padding-bottom: 0;

    @media (min-width: ${theme.breakpoints.tablet}px) {
      height: ${getInputHeight(false)}px;
      padding-top: ${getInputPaddingTop(false)}px;
    }
  }

  input,
  textarea {
    position: relative;
    width: 100%;
    ${wrapperPaddings(true)}
    border: 0;
    box-sizing: border-box;
    font-family: ${theme.fonts.font_family};
    font-size: ${theme.fonts.font_sizes.par.lg}px;
    line-height: ${theme.fonts.line_heights.par.lg}px;
    color: ${({ disabled, variant }) =>
      variant == 'privateBanking'
        ? theme.colors.neutral.g800
        : disabled
        ? theme.colors.neutral.g500
        : theme.colors.neutral.g100};
    caret-color: ${({ variant }) => variant == 'privateBanking' && theme.colors.neutral.g800};
    background-color: ${({ disabled, readOnly, variant }) =>
      variant == 'privateBanking'
        ? 'transparent'
        : disabled || readOnly
        ? variant == 'premiumBanking'
          ? theme.colors.neutral.g800
        : theme.colors.neutral.g700
        : theme.colors.neutral.g800};
    pointer-events: ${({ disabled, readOnly }) => (disabled || readOnly ? 'none' : 'all')};
    outline: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    ${borderRadiusMixin};

    @media (min-width: ${theme.breakpoints.tablet}px) {
      ${wrapperPaddings(false)}
      ${({ size }) =>
        size === 'xl' &&
        css`
          font-size: ${theme.fonts.font_sizes.par[size]}px;
          line-height: ${theme.fonts.line_heights.par[size]}px;
        `}
    }

    ::placeholder {
      color: ${({ hasInputValue, label, variant, isFocused }) =>
        variant == 'privateBanking' || variant ==  'premiumBanking'
          ? hasInputValue || !label || isFocused
            ? theme.colors.neutral.g300
            : 'transparent'
          : hasInputValue || !label || isFocused
          ? theme.colors.neutral.g400
          : 'transparent'};
      opacity: 1;

      @media (min-width: ${theme.breakpoints.tablet}px) {
        color: ${({ isLabelInside, variant, disabled }) =>
         !isLabelInside 
          ? variant == 'premiumBanking'
            ? disabled 
              ? theme.colors.neutral.g500
              : theme.colors.neutral.g300
          : theme.colors.neutral.g400
          : null
        };
      }
    }

    ::-webkit-credentials-auto-fill-button {
      position: absolute;
      right: 0;
      visibility: hidden;
      pointer-events: none;
    }

    :-webkit-autofill {
      box-shadow: 0 0 0 100px
        ${({ variant, disabled }) => (
          variant == 'privateBanking' 
          ? 'rgba(0,0,0,.9)' 
          : disabled 
            ? variant == 'premiumBanking'
              ? theme.colors.white
              : theme.colors.neutral.g700
          : theme.colors.white)}
        inset;
      -webkit-text-fill-color: ${({ variant, disabled }) =>
        variant == 'privateBanking' 
        ? theme.colors.white
        : disabled
          ? theme.colors.neutral.g500
          : theme.colors.black
      } !important;
    }
  }
`

export const Styled = {
  Wrapper,
  StartIcon,
  EndIcon,
  InputWrapper,
}
