import React, { forwardRef, useMemo, useState } from 'react'


import { SkeletonCir } from '../Skeleton'

import { Styled } from './styled'

import { BASE_URL } from 'constants/urls'

const iconStoragePrefix = `${BASE_URL}/icons/`
const getIconSrc = (icon: string, isCustomIcon: boolean) => {
  return `${!isCustomIcon ? iconStoragePrefix : ''}${!isCustomIcon ? `${icon}.svg` : icon}`
}

export type IconSize = 'xl' | 'lg' | 'md' | 'sm'

export interface IconProps extends Omit<React.HTMLAttributes<HTMLOrSVGElement>, 'onError'> {
  /** Имя иконки из библиотеки иконок или путь до кастомной иконки  */
  icon: string
  /** Флаг указывающий, что иконка не из библиотеки иконок */
  isCustomIcon?: boolean
  /** Добавляет div как враппер для svg */
  withWrapper?: boolean
  /** Компонент, который показывается во время загрузки SVG */
  loader?: React.ReactNode
  /** Размер скелетона, который показывается во время загрузки SVG */
  loaderSize?: number | string
  /** Коллбек который будет вызван, если при загрузке произойдет ошибка */
  onError?: (error?: Error) => void
  /** Коллбек который будет вызван, сразу после загрузки иконки */
  onLoad?: () => void
  /** Функция получающая svg как текст, для преобразования */
  preProcessor?: (arg?: string) => string
  /** (Svg атрибут) Ширина иконки */
  width?: number
  /** (Svg атрибут) Высота иконки */
  height?: number
  /** (Svg атрибут) Границы viewport */
  viewBox?: string
  /** (Svg атрибут) Цвет иконки */
  color?: string
  /** (Svg атрибут) Координата по X */
  x?: number
  /** (Svg атрибут) Координата по Y */
  y?: number
  size?: IconSize
  fill?: string
}

export const Icon = forwardRef<HTMLElement | SVGElement, IconProps>(
  (
    {
      icon,
      isCustomIcon = false,
      withWrapper = false,
      loader,
      loaderSize = 16,
      onError,
      onLoad,
      preProcessor,
      width,
      height,
      viewBox,
      color,
      x,
      y,
      className,
      ...rest
    },
    ref,
  ) => {
    const [hasError, setHasError] = useState(false)

    const src = useMemo(() => getIconSrc(icon, isCustomIcon), [icon])

    const testId = `icon_${icon}`
    const size = icon ? icon.match(/\d\d(?=\/)/) : false
    const skeletonSize = size ? size[0] : loaderSize
    const skeleton = (
      <SkeletonCir height={skeletonSize} data-testid={testId} onClick={rest.onClick} />
    )

    const handleError = (error: Error) => {
      setHasError(true)

      onError && onError(error)
    }

    /** Костыль, т.к. если в компонент SVG передать undefined, он затрет нативные атрибуты в иконке */
    const svgProps = {
      ...(width && { width }),
      ...(height && { height }),
      ...(viewBox && { viewBox }),
      ...(color && { color }),
      ...(x && { x }),
      ...(y && { y }),
      loader: loader || loader === null ? loader : skeleton,
      onError: handleError,
      onLoad,
      preProcessor,
    }

    if (hasError) return skeleton

    if (withWrapper) {
      return (
        <Styled.Wrapper
          data-testid={testId}
          ref={ref as React.Ref<HTMLElement>}
          className={className}
          {...rest}
        >
          <Styled.Svg src={src} {...svgProps} />
        </Styled.Wrapper>
      )
    }

    return (
      <Styled.Svg
        data-testid={testId}
        innerRef={ref as React.Ref<SVGElement>}
        src={src}
        className={className}
        {...{
          ...svgProps,
          ...rest,
        }}
      />
    )
  },
)
