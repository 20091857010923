/* eslint-disable no-undefined */

import React, {
  useState,
  useEffect,
  FC,
  RefObject,
  FocusEvent,
  SyntheticEvent,
  MutableRefObject,
} from 'react'

import { Styled } from './styled'

import { TextFieldWrapperProps } from 'components/molecules/moleculeTypes'
import { InputLabel } from 'components/atoms/InputLabel'
import { InputControl, InputControlProps } from 'components/atoms/InputControl'
import { useBreakpoint } from 'hooks/index'

export const TextFieldWrapper: FC<TextFieldWrapperProps> & {
  hasInputValue?: (value: React.ReactText) => boolean
} = ({
  label,
  tooltip,
  disabled,
  readOnly,
  placeholderChar,
  hasError,
  value,
  mask,
  isHidden,
  children,
  size,
  showGuide,
  isClearable,
  clearThreshold,
  onClear,
  onFocus,
  onBlur,
  onClick,
  icon,
  endIcon,
  iconColor,
  hasControl,
  hasDropdown,
  isDropdownOpened,
  componentRef,
  isAutoResize,
  rows,
  isTextarea,
  fieldChildren,
  asPassword,
  onPasswordShow,
  isVisible,
  controlChildren,
  className,
  variant,
}) => {
  const [hasInputValueState, setHasInputValue] = useState(false)
  const [inputFocusedState, setInputFocused] = useState(false)

  const { isMobile } = useBreakpoint()

  const isLabelInsideInput = size === 'xl' || isMobile
  const withTooltip = isLabelInsideInput && !!tooltip
  const withControl = (isClearable && !!onClear) || withTooltip || !!controlChildren || hasControl || hasDropdown
  const isClearIconHidden = !isClearable || (value as string)?.length <= clearThreshold

  const inputWrapperProps = {
    hasInputValue: hasInputValueState,
    isLabelInside: isLabelInsideInput,
    hasControl: hasControl || isClearable || withTooltip,
    isTextarea,
    size,
    hasError,
    disabled,
    readOnly,
    icon,
    label,
    rows,
    asPassword,
    endIcon,
    tooltip,
    hasDropdown,
    isClearIconHidden,
    controlChildren: !!controlChildren,
    variant,
    isFocused: inputFocusedState
  }

  const controlProps = {
    isClearable,
    clearThreshold,
    size,
    value,
    tooltip,
    asPassword,
    onPasswordShow,
    isVisible,
    hasDropdown,
    icon,
    disabled,
    readOnly,
    isOpen: isDropdownOpened,
    variant,
  } as InputControlProps

  const setCaret = (event: SyntheticEvent<HTMLInputElement>) => {
    if (!showGuide || !mask) return

    const input = event.target as HTMLInputElement
    const index = input.value.indexOf(placeholderChar)

    index >= 0 && input.setSelectionRange(index, index)
  }

  const setRef = (tag: HTMLInputElement) => {
    if (!componentRef) return
    if ((componentRef as RefObject<HTMLInputElement>).current) return
    if (typeof componentRef === 'function') {
      componentRef(tag)
      return
    }
    (componentRef as MutableRefObject<HTMLInputElement>).current = tag
  }

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setCaret(event)
    onClick && onClick(event)
  }

  const handleMaskedInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    setCaret(event)
    setHasInputValue(true)
    setInputFocused(true)
    onFocus && onFocus(event)
  }

  const handleInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    setHasInputValue(true)
    setInputFocused(true)
    onFocus && onFocus(event)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setInputFocused(false)
    !event.target.value && setHasInputValue(false)
    onBlur && onBlur(event)
  }

  const handleClear = (event: React.MouseEvent<HTMLInputElement>) => {
    !inputFocusedState && setHasInputValue(false)
    onClear && onClear(event)
  }

  useEffect(() => {
    TextFieldWrapper.hasInputValue(value) ? setHasInputValue(true) : setHasInputValue(false) 
  }, [value])
  
  return (
    <Styled.Wrapper
      className={className}
      disabled={disabled}
      readOnly={readOnly}
      variant={variant}
      isHidden={isHidden}
    >
      {label && (
        <InputLabel
          isInside={isLabelInsideInput}
          hasInputValue={hasInputValueState}
          isFocused={inputFocusedState}
          hasIcon={!!icon}
          hasEndIcon={!!endIcon}
          hasControl={hasControl || isClearable || withTooltip}
          hasScroll={isTextarea && !isAutoResize}
          {...inputWrapperProps}
        />
      )}
      <Styled.InputWrapper {...inputWrapperProps}>
        {icon && <Styled.StartIcon withWrapper icon={icon} color={iconColor} size={size} />}

        {children({ setRef, handleClick, handleInputFocus, handleMaskedInputFocus, handleBlur })}

        {withControl && (
          <InputControl {...controlProps} hasEndIcon={!!endIcon} onClear={handleClear}>
            {controlChildren}
          </InputControl>
        )}

        {fieldChildren}
        {endIcon && <Styled.EndIcon withWrapper icon={endIcon} color={iconColor} size={size} />}
      </Styled.InputWrapper>
    </Styled.Wrapper>
  )
}

TextFieldWrapper.hasInputValue = (value: string | number) => typeof value === 'number' || !!value

TextFieldWrapper.defaultProps = {
  label: '',
  tooltip: '',
  disabled: false,
  type: 'text',
  hasError: false,
  readOnly: false,
  mask: null,
  isHidden: false,
  size: 'lg',
  isClearable: false,
  clearThreshold: 0,
  showGuide: false,
  placeholderChar: '_',
  hasControl: false,
  componentRef: null,
  isAutoResize: false,
  rows: 3,
  isTextarea: false,
  iconColor: '',
  icon: null,
  children: null,
  onClear: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onClick: () => {},
}
