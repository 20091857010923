import React, { FC, FocusEvent, HTMLAttributes, MouseEvent, Ref, ReactNode } from 'react'
import MaskedInput, { maskArray, MaskedInputProps } from 'react-text-mask'

import { TextFieldWrapper } from '../TextFieldWrapper'

import { isFunc, setReference } from './utils'

import { TextFieldWrapperProps } from 'components/molecules/moleculeTypes'

// TODO: перенести в TextField и объединить логику с TextArea. Убрать TextFieldWrapper из-за его сложности
export interface InputProps
  extends Omit<MaskedInputProps, 'size'>,
    Pick<
      TextFieldWrapperProps,
      'asPassword' | 'onPasswordShow' | 'isVisible' | 'controlChildren' | 'isDropdownOpened' | 'variant'
    > {
  label: string
  tooltip: string | ReactNode
  disabled: boolean
  placeholder: string
  type: string
  hasError: boolean
  readOnly: boolean
  value: string | number
  mask: maskArray | ((value: string) => maskArray)
  size: 'xl' | 'lg' | 'md'
  isHidden: boolean
  isClearable: boolean
  onClear: (event: React.MouseEvent<HTMLInputElement>) => void
  onFocus: (event: FocusEvent<HTMLInputElement>) => void
  onBlur: (event: FocusEvent<HTMLInputElement>) => void
  onClick: (event: MouseEvent<HTMLInputElement>) => void
  clearThreshold: number
  showGuide: boolean
  placeholderChar: string
  showMask: boolean
  hasControl: boolean
  hasDropdown: boolean
  icon: string
  endIcon: string
  iconColor: string
  componentRef: Ref<HTMLInputElement>
  isAutoResize: boolean
  rows: number
  isTextarea: boolean
}

export const Input: FC<Partial<InputProps>> = ({
  label,
  tooltip,
  disabled,
  readOnly,
  placeholder,
  placeholderChar,
  type,
  hasError,
  value,
  mask,
  isHidden,
  size,
  isClearable,
  onClear,
  onFocus,
  onBlur,
  onClick,
  clearThreshold,
  showGuide,
  showMask,
  icon,
  endIcon,
  iconColor,
  keepCharPositions,
  hasControl,
  hasDropdown,
  isDropdownOpened,
  componentRef,
  children,
  asPassword,
  onPasswordShow,
  isVisible,
  controlChildren,
  className,
  variant,
  ...rest
}) => {
  const textFieldWrapperProps = {
    // TODO: isFunc сделано для AutoComplete, выпилить проверку, когда заменим react-autosuggest
    componentRef: isFunc(componentRef) ? null : componentRef,
    fieldChildren: children,
    label,
    tooltip,
    disabled,
    readOnly,
    placeholderChar,
    hasError,
    value,
    mask,
    isHidden,
    size,
    isClearable,
    clearThreshold,
    onClear,
    onFocus,
    onBlur,
    onClick,
    showGuide,
    icon,
    endIcon,
    iconColor,
    hasControl,
    hasDropdown,
    isDropdownOpened,
    showMask,
    asPassword,
    onPasswordShow,
    isVisible,
    controlChildren,
    className,
    variant
  } as TextFieldWrapperProps

  const inputProps = {
    disabled,
    readOnly,
    placeholder,
    type,
    value,
    spellCheck: false,
    ...rest,
  } as MaskedInputProps | HTMLAttributes<HTMLInputElement>

  return (
    <TextFieldWrapper {...textFieldWrapperProps}>
      {({ setRef, handleClick, handleInputFocus, handleMaskedInputFocus, handleBlur }) => (
        <>
          {mask ? (
            <MaskedInput
              mask={mask}
              keepCharPositions={keepCharPositions}
              guide={showGuide}
              showMask={showMask}
              placeholderChar={placeholderChar}
              ref={setReference(componentRef, setRef)}
              onClick={handleClick}
              onFocus={handleMaskedInputFocus}
              onBlur={handleBlur}
              {...inputProps}
            />
          ) : (
            <input
              ref={componentRef}
              onFocus={handleInputFocus}
              onBlur={handleBlur}
              onClick={handleClick}
              {...inputProps}
            />
          )}
        </>
      )}
    </TextFieldWrapper>
  )
}

Input.defaultProps = {
  label: '',
  tooltip: '',
  disabled: false,
  placeholder: '',
  type: 'text',
  hasError: false,
  readOnly: false,
  mask: null,
  isHidden: false,
  size: 'lg',
  isClearable: false,
  clearThreshold: 0,
  showGuide: false,
  placeholderChar: '_',
  showMask: false,
  hasControl: false,
  componentRef: null,
  icon: null,
  iconColor: '',
  onClear: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onClick: () => {},
}
