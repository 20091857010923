import { WrapperProps } from './types'

import { theme } from 'theme'


const iconSizes = {
  xl: 52,
  lg: 52,
  md: 44,
  sm: 32,
} as const


export const getRightPadding = ({
  size,
  hasEndIcon,
  isMobile,
}: {
  size: keyof typeof theme.sizes.greater
  hasEndIcon: boolean
  isMobile: boolean
}) => {
  return hasEndIcon
    ? theme.sizes.greater.md
    : !isMobile
    ? theme.inputControl_paddings[size]
    : theme.inputControl_paddings.mobile
}
export const getWidthSideSize = (
  {
    hasDropdown,
    isClearIconHidden,
    hasAdditionalItem,
    size,
    isMobile,
    hasEndIcon,
    tooltip,
    icon,
  }: Omit<WrapperProps, 'isAllIconsHidden'>,
  paddingSide?: 'left' | 'right',
): number => {
  const fullControlSize = iconSizes.lg

  const clearIconMargin = 5

  if (paddingSide === 'left') {
    if (icon) {
      return iconSizes[isMobile ? 'lg' : size]
    } else {
      return theme.spacings.xs
    }
  } else {
    if ((hasAdditionalItem || tooltip) && isClearIconHidden) {
      return fullControlSize
    }
    if (hasDropdown && !isClearIconHidden) {
      if (tooltip) return theme.spacings.xl4
      return fullControlSize + theme.spacings.xs
    }
    if (hasDropdown && isClearIconHidden) {
      return theme.spacings.lg
    }
    if (hasEndIcon && !isClearIconHidden) {
      return theme.sizes.greater.sm + getRightPadding({ size, hasEndIcon, isMobile })
    }
    if (hasEndIcon && isClearIconHidden) {
      return theme.sizes.greater.md
    }
    if (!hasEndIcon && isClearIconHidden && !hasAdditionalItem && !tooltip && !hasDropdown) {
      return theme.spacings.xs
    } else {
      return (
        theme.sizes.greater.md + getRightPadding({ size, hasEndIcon, isMobile }) + clearIconMargin
      )
    }
  }
}

export const getHeightSideSize = (isMobile: boolean) => ({
  size,
}: Omit<WrapperProps, 'isAllIconsHidden'>): number => {
  const bordersSize = theme.border_sizes.basic * 2
  const fullControlSize = isMobile ? theme.sizes.greater.lg : theme.sizes.greater[size]
  const calculatedSize = fullControlSize - bordersSize
  return calculatedSize
}
