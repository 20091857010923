import styled from 'styled-components'

import { TextProps } from 'components/atoms/atomsTypes'
import { theme } from 'theme'

export const resetedLineHeight = '1em'

const {
  fonts: { font_sizes, font_weights, font_family, line_heights },breakpoints
} = theme

const Wrapper = styled.div<
  Pick<TextProps, 'sizemob' | 'size' | 'resetLineHeight' | 'align' | 'capitalize'> & {
    weight?: keyof typeof theme.fonts.font_weights
    $color?: TextProps['color']
  }
>`
  white-space: pre-line;
  margin-top: 0; /* margins needs for as={'p'} prop */
  margin-bottom: 0;
  font-family: ${font_family};
  font-size: ${({ sizemob, size }) => font_sizes.par[sizemob || size]}px;
  font-weight: ${({ weight }) => font_weights[weight]};
  line-height: ${({ sizemob, size, resetLineHeight }) =>
    resetLineHeight ? resetedLineHeight : `${line_heights.par[sizemob || size]}px`};
  text-align: ${({ align }) => align};
  text-transform: ${({ capitalize }) => (capitalize ? 'uppercase' : 'none')};
  color: ${({ $color }) => $color};
  
  transition: ${theme.transitions.basic};

  strong {
    font-weight: ${theme.fonts.font_weights.bold};
  }

  b {
    font-weight: ${theme.fonts.font_weights.medium};
  }

  @media (min-width: ${breakpoints.sm}px) {
    font-size: ${({ size }) => font_sizes.par[size]}px;
    line-height: ${({ size, resetLineHeight }) =>
      resetLineHeight ? resetedLineHeight : `${line_heights.par[size]}px`};
  }
`

export const Styled = {
  Wrapper,
}
