import { HTMLAttributes } from 'react'
import styled from 'styled-components'

import { getTextareaHeight } from 'components/molecules/TextFieldWrapper/utils'
import { theme } from 'theme'

const getTextareaWrapperHeight = (isMobile: boolean) => ({ isAutoResize, rows, size }: TextareaWrapperProps) =>
  isAutoResize ? null : `${getTextareaHeight(isMobile)({ rows, size })}px`

interface TextareaWrapperProps extends HTMLAttributes<HTMLDivElement> {
  isAutoResize: boolean
  rows: number
  size: 'xl'|'lg'|'md'
}

const TextareaWrapper = styled.div<TextareaWrapperProps>`
  display: flex;
  width: 100%;
  min-height: ${getTextareaHeight(true)}px;
  height: ${getTextareaWrapperHeight(true)};

  @media (min-width: ${theme.breakpoints.tablet}px) {
    min-height: ${getTextareaHeight(false)}px;
    height: ${getTextareaWrapperHeight(false)};
  }
`

export const Styled = {
  TextareaWrapper,
}
