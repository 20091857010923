import React, { FC, HTMLAttributes, ReactNode } from 'react'

import { Styled } from './styled'

import { ToolTip } from 'components/atoms/ToolTip/ToolTip'
import { useBreakpoint } from 'hooks/index'
import { TextFieldWrapperProps } from 'components/molecules/moleculeTypes'

export interface InputLabelProps
  extends HTMLAttributes<HTMLDivElement>,
    Pick<TextFieldWrapperProps, 'variant'> {
  size?: 'xl' | 'lg' | 'md'
  label?: string
  tooltip?: string | ReactNode
  isInside?: boolean
  hasInputValue?: boolean
  isFocused?: boolean
  hasIcon?: boolean
  hasScroll?: boolean
  hasEndIcon?: boolean
  disabled?: boolean
  readOnly?: boolean
  isClearIconHidden: boolean
  controlChildren: boolean
}

export const InputLabel: FC<InputLabelProps> = ({
  label,
  tooltip,
  isInside,
  hasInputValue,
  isFocused,
  size,
  hasIcon,
  hasScroll,
  disabled,
  readOnly,
  isClearIconHidden,
  hasEndIcon,
  variant,
  ...rest
}) => {
  const { isMobile } = useBreakpoint()

  const textElement = (
    <Styled.LabelText
      variant={variant}
      isInside={isInside}
      hasInputValue={hasInputValue}
      isFocused={isFocused}
      disabled={disabled}
      size={size}
    >
      {label}
    </Styled.LabelText>
  )

  return (
    <Styled.LabelWrapper
      variant={variant}
      size={size}
      isInside={isInside}
      hasIcon={hasIcon}
      tooltip={tooltip}
      hasScroll={hasScroll}
      isFocused={isFocused}
      hasInputValue={hasInputValue}
      disabled={disabled}
      readOnly={readOnly}
      isClearIconHidden={isClearIconHidden}
      hasEndIcon={hasEndIcon}
      {...rest}
    >
      {isMobile ? (
        <Styled.LabelMobile>{textElement}</Styled.LabelMobile>
      ) : (
        <Styled.LabelDesktop>
          {tooltip && !isInside 
            ? <ToolTip onClick={e => e.stopPropagation()} hint={tooltip}>{textElement}</ToolTip> 
            : textElement
          }
        </Styled.LabelDesktop>
      )}
    </Styled.LabelWrapper>
  )
}

InputLabel.defaultProps = {
  label: '',
  tooltip: '',
  isInside: false,
  hasInputValue: false,
  isFocused: false,
  size: 'lg',
  hasIcon: false,
  isClearIconHidden: true,
  hasScroll: false,
}
