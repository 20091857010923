import { theme } from 'theme'

const {
  colors: { transparent, white, black, red, neutral, emerald },
} = theme

const ratios = {
  default: 0.2,
  disabled: 0.1,
  disabledText: 0.3,
  focus: 0.4,
  hover: 0.4,
  active: 0.6,
}

const getLightColor = (ratio: number) => `rgba(255, 255, 255, ${ratio})`
const getDarkColor = (ratio: number) => `rgba(0, 0, 0, ${ratio})`

export const buttonStates = {
  default: 'default',
  hover: 'hover',
  active: 'active',
  focus: 'focus',
}

export const buttonColorTypes = {
  default: 'default',
  light: 'light',
  dark: 'dark',
  premium: 'premium'
} as const

export const buttonColors = {
  primary: 'primary',
  secondary: 'secondary',
  outline: 'outline',
  ghost: 'ghost',
  disabled: 'disabled',
  loading: 'loading',
  done: 'done',
  hover: 'hover',
  active: 'active',
  focus: 'focus',
} as const

export const buttonHeights = {
  sm: 32,
  md: 44,
  lg: 52,
  xl: 72,
}

export const buttonPaddings = {
  onlyText: {
    sm: '0 16px',
    md: '0 20px',
    lg: '0 32px',
    xl: '0 40px',
  },
  withStartIcon: {
    sm: '0 12px 0 10px',
    md: '0 16px 0 12px',
    lg: '0 20px 0 16px',
    xl: '0 28px 0 24px',
  },
  withEndIcon: {
    sm: '0 10px 0 12px',
    md: '0 12px 0 16px',
    lg: '0 16px 0 20px',
    xl: '0 24px 0 28px',
  },
}

export const textSizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
} as const

export const defaultIconSizes = {
  sm: '16',
  md: '24',
  lg: '24',
  xl: '24',
}

export const buttonColorsConfig = {
  default: {
    [buttonColors.primary]: {
      background: red.primary,
      text: white,
      border: red.primary,
    },
    [buttonColors.secondary]: {
      background: neutral.g600,
      text: neutral.g100,
      border: transparent,
    },
    [buttonColors.outline]: {
      background: transparent,
      text: neutral.g100,
      border: neutral.g600,
    },
    [buttonColors.ghost]: {
      background: transparent,
      text: neutral.g100,
      border: transparent,
    },
    [buttonColors.disabled]: {
      background: neutral.g700,
      text: neutral.g400,
      border: neutral.g700,
    },
    [buttonColors.loading]: {
      background: neutral.g700,
      text: neutral.g100,
      border: neutral.g700,
    },
    [buttonColors.done]: {
      background: neutral.g700,
      text: neutral.g100,
      border: neutral.g700,
    },
    [buttonColors.hover]: {
      background: red.hover,
      text: white,
      border: transparent,
    },
    [buttonColors.active]: {
      background: red.active,
      text: white,
      border: red.active,
    },
    [buttonColors.focus]: {
      background: red.hover,
      text: white,
      border: red.hover,
    },
  },
  light: {
    [buttonColors.primary]: {
      background: getLightColor(ratios.default),
      text: white,
    },
    [buttonColors.secondary]: {
      background: getLightColor(ratios.default),
      text: white,
    },
    [buttonColors.outline]: {
      background: getLightColor(ratios.default),
      text: white,
    },
    [buttonColors.ghost]: {
      background: transparent,
      text: white,
    },
    [buttonColors.disabled]: {
      background: getLightColor(ratios.disabled),
      text: getLightColor(ratios.disabledText),
    },
    [buttonColors.loading]: {
      background: getLightColor(ratios.disabled),
      text: white,
    },
    [buttonColors.done]: {
      background: getLightColor(ratios.disabled),
      text: white,
    },
    [buttonColors.hover]: {
      background: getLightColor(ratios.hover),
      text: white,
    },
    [buttonColors.active]: {
      background: getLightColor(ratios.active),
      text: white,
    },
    [buttonColors.focus]: {
      background: getLightColor(ratios.focus),
      text: white,
    },
  },
  dark: {
    [buttonColors.primary]: {
      background: getDarkColor(ratios.default),
      text: black,
    },
    [buttonColors.secondary]: {
      background: getDarkColor(ratios.default),
      text: black,
    },
    [buttonColors.outline]: {
      background: getDarkColor(ratios.default),
      text: black,
    },
    [buttonColors.ghost]: {
      background: transparent,
      text: black,
    },
    [buttonColors.disabled]: {
      background: getDarkColor(ratios.disabled),
      text: getDarkColor(ratios.disabledText),
    },
    [buttonColors.loading]: {
      background: getDarkColor(ratios.disabled),
      text: black,
    },
    [buttonColors.done]: {
      background: getDarkColor(ratios.disabled),
      text: black,
    },
    [buttonColors.hover]: {
      background: getDarkColor(ratios.hover),
      text: black,
    },
    [buttonColors.active]: {
      background: getDarkColor(ratios.active),
      text: black,
    },
    [buttonColors.focus]: {
      background: getDarkColor(ratios.focus),
      text: black,
    },
  },
  premium: {
    [buttonColors.primary]: {
      background: emerald.primary,
      text: white,
      border: emerald.primary,
    },
    [buttonColors.secondary]: {
      background: neutral.g600,
      text: neutral.g100,
      border: transparent,
    },
    [buttonColors.outline]: {
      background: transparent,
      text: neutral.g100,
      border: neutral.g400,
    },
    [buttonColors.ghost]: {
      background: transparent,
      text: neutral.g100,
      border: transparent,
    },
    [buttonColors.disabled]: {
      background: neutral.g500,
      text: neutral.g400,
      border: neutral.g500,
    },
    [buttonColors.loading]: {
      background: neutral.g700,
      text: neutral.g100,
      border: neutral.g700,
    },
    [buttonColors.done]: {
      background: neutral.g700,
      text: neutral.g100,
      border: neutral.g700,
    },
    [buttonColors.hover]: {
      background: emerald.hover,
      text: white,
      border: transparent,
    },
    [buttonColors.active]: {
      background: emerald.active,
      text: white,
      border: emerald.active,
    },
    [buttonColors.focus]: {
      background: emerald.hover,
      text: white,
      border: emerald.hover,
    },
  }
}
