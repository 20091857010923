import { ReactNode } from 'react'
import { css } from 'styled-components'

import { TextFieldWrapperProps } from '../moleculeTypes'

import { getWidthSideSize } from 'components/atoms/InputControl/utility'
import { theme } from 'theme'
export interface InputWrapperProps extends Pick<TextFieldWrapperProps, 'variant'> {
  hasError?: boolean
  disabled?: boolean
  readOnly?: boolean
  isLabelInside?: boolean
  isClearIconHidden?: boolean
  hasControl?: boolean
  hasDropdown?: boolean
  rows?: number
  size?: 'xl' | 'lg' | 'md'
  label?: string
  hasInputValue?: boolean
  icon?: string
  endIcon?: string
  asPassword?: boolean
  tooltip?: string | ReactNode
  controlChildren?: boolean
  isTextarea?: boolean
  isFocused?: boolean
}

const isInsideLabel = (isMobile: boolean, hasLabel: boolean, isLabelInside: boolean) =>
  hasLabel && (isMobile || isLabelInside)

export const textareaTopPaddings = {
  md: 10,
  lg: 13,
  xl: 20,
} as const

export const iconSizes = {
  xl: 52,
  lg: 52,
  md: 44,
  sm: 32,
} as const

export const getIconWidth = (size = 'lg') => iconSizes[size]

export const getTextareaHeight =
  (isMobile: boolean) =>
  ({ rows, size }: Pick<InputWrapperProps, 'rows' | 'size'>) => {
    const textAreaHeight = isMobile ? theme.sizes.greater.lg : theme.sizes.greater[size]
    const textAreaInsideHeight = textAreaHeight - theme.border_sizes.basic * 2
    const lineHeight = isMobile
      ? theme.fonts.line_heights.par.lg
      : theme.fonts.line_heights.par[size]

    return (rows - 1) * lineHeight + textAreaInsideHeight
  }

export const getInputPaddingTop =
  (isMobile: boolean) =>
  ({ label, isLabelInside }: Pick<InputWrapperProps, 'label' | 'isLabelInside'>) => {
    const hasLabelInside = isInsideLabel(isMobile, !!label, isLabelInside)
    const inputTopLabelPadding = 14

    return hasLabelInside ? inputTopLabelPadding : 0
  }

export const getTextAreaPaddingTop =
  (isMobile: boolean) =>
  ({
    label,
    isLabelInside,
    hasInputValue,
    size,
  }: Pick<InputWrapperProps, 'label' | 'isLabelInside' | 'hasInputValue' | 'size'>) => {
    const hasLabelInside = isInsideLabel(isMobile, !!label, isLabelInside)

    if ((isMobile && !!label) || (hasLabelInside && hasInputValue && isMobile))
      return theme.fonts.line_heights.par.md
    if (isMobile && !label) return textareaTopPaddings.lg
    if (hasLabelInside && hasInputValue) return theme.fonts.line_heights.par.xl
    if (hasLabelInside) return theme.fonts.line_heights.par[size]

    return textareaTopPaddings[size]
  }

export const getInputBorderColor =
  (isHovered: boolean) =>
  ({
    disabled,
    readOnly,
    hasError,
    variant,
  }: Pick<InputWrapperProps, 'disabled' | 'readOnly' | 'hasError' | 'variant'>) => {
    if (hasError) return theme.colors.orange.primary
    if (variant == 'privateBanking') {
      if (disabled || readOnly) return theme.colors.neutral.g300
      return isHovered ? theme.colors.neutral.g500 : theme.colors.neutral.g300
    } 
    else if (variant == 'premiumBanking') {
      if (disabled || readOnly) return theme.colors.neutral.g600
      return isHovered ? theme.colors.neutral.g500 : theme.colors.neutral.g300
    } else {
      if (disabled || readOnly) return theme.colors.neutral.g600

      return isHovered ? theme.colors.neutral.g100 : theme.colors.neutral.g500
    }
  }

export const getInputHeight =
  (isMobile: boolean) =>
  ({ size }: Pick<InputWrapperProps, 'size'>) => {
    const inputSize = isMobile ? theme.sizes.greater.lg : theme.sizes.greater[size]

    return inputSize - theme.border_sizes.basic * 2
  }

export const wrapperPaddings = (isMobile: boolean) =>
  css<
    Pick<
      InputWrapperProps,
      | 'size'
      | 'icon'
      | 'endIcon'
      | 'asPassword'
      | 'hasDropdown'
      | 'tooltip'
      | 'isClearIconHidden'
      | 'controlChildren'
    >
  >`
    padding-left: ${({
      size,
      icon,
      endIcon,
      asPassword,
      hasDropdown,
      tooltip,
      isClearIconHidden,
    }) =>
      getWidthSideSize(
        {
          hasDropdown,
          isClearIconHidden,
          hasAdditionalItem: asPassword,
          size,
          isMobile: isMobile,
          hasEndIcon: !!endIcon,
          icon: !!icon,
          tooltip,
        },
        'left',
      )}px;
    padding-right: ${({
      size,
      icon,
      endIcon,
      asPassword,
      hasDropdown,
      tooltip,
      isClearIconHidden,
      controlChildren,
    }) =>
      getWidthSideSize(
        {
          hasDropdown,
          isClearIconHidden,
          hasAdditionalItem: asPassword || controlChildren,
          size,
          isMobile: isMobile,
          hasEndIcon: !!endIcon,
          icon: !!icon,
          tooltip,
        },
        'right',
      )}px;
  `
