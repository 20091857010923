import React, { forwardRef } from 'react'

import { Styled } from './styled'
import { HeadingProps } from './types'

import { theme } from 'theme'

export const Heading = forwardRef<HTMLDivElement, HeadingProps>(
  ({ children, h, as, color, ...restProps }, ref) => (
    <Styled.Wrapper
      ref={ref}
      data-testid="heading"
      as={as || (`h${h}` as React.ElementType)}
      h={h}
      $color={color}
      {...restProps}
    >
      {children}
    </Styled.Wrapper>
  ),
)

Heading.defaultProps = {
  color: theme.colors.neutral.g100,
  fontWeight: 'bold',
  capitalize: false,
  align: 'left',
}
