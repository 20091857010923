// Если что-то меняете в Button, то проверяйте, что ничего не сломалось в ButtonGroup
import styled, { css } from 'styled-components'

import { buttonStates, buttonHeights } from './config'
import {
  shadowFocusBorder,
  getButtonBackgroundColor,
  getButtonBorderColor,
  getButtonTextColor,
  getButtonPadding,
  getBorderRadius,
  isButtonActive,
} from './utils'
import { WrapperProps } from './types'

import { Text } from 'components/atoms/Text'
import { Flex } from 'components/atoms/Primitives'
import { theme } from 'theme'

const Inner = styled(Flex)<{ tabIndex: number | string }>`` // for only keyboard focus

const Wrapper = styled.button<WrapperProps>`
  position: relative;
  z-index: 1; /* for outlined ButtonGroup */
  display: inline-flex;
  width: ${({ fluidMob }) => (fluidMob ? '100%' : 'auto')};
  min-width: ${({ sizemob }) => buttonHeights[sizemob]}px;
  transition: ${theme.transitions.basic};
  appearance: none;
  box-sizing: border-box;
  background: none;
  border: none;
  outline: none;
  font-size: inherit;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  pointer-events: ${props => !isButtonActive(props) && 'none'};

  ${props =>
    isButtonActive(props) &&
    css<WrapperProps>`
      &:focus {
        z-index: 20; /* for outlined ButtonGroup */
      }

      &:hover {
        z-index: 10; /* for outlined ButtonGroup */
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover > ${Inner} {
          border-color: ${props => getButtonBorderColor(props, buttonStates.hover)};
          background-color: ${props => getButtonBackgroundColor(props, buttonStates.hover)};
          color: ${props => getButtonTextColor(props, buttonStates.hover)};
        }
      }

      &:focus {
        outline: auto;
        & > ${Inner} {
          &:before {
            opacity: 1;
          }
        }
      }

      &:active > ${Inner} {
        border-color: ${props => getButtonBorderColor(props, buttonStates.active)};
        background-color: ${props => getButtonBackgroundColor(props, buttonStates.active)};
        color: ${props => getButtonTextColor(props, buttonStates.active)};
      }
    `}
  ${({ type }) =>
    type === buttonStates.default &&
    css<WrapperProps>`
      & > ${Inner}:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: ${getBorderRadius};
        box-shadow: ${shadowFocusBorder};
        transition: ${theme.transitions.basic};
        opacity: 0;
      }
    `}
  

  & > ${Inner} {
    height: ${({ sizemob }) => buttonHeights[sizemob]}px;
    width: 100%;
    padding: ${props => getButtonPadding(props)};
    box-sizing: border-box;
    border: ${theme.borders.basic} ${props => getButtonBorderColor(props)};
    border-radius: ${getBorderRadius};
    background-color: ${getButtonBackgroundColor};
    color: ${getButtonTextColor};
    transition: border-color ${theme.transitions.basic}, background-color ${theme.transitions.basic};
    outline: none;
  }

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
    min-width: ${({ size }) => buttonHeights[size]}px;

    & > ${Inner} {
      height: ${({ size }) => buttonHeights[size]}px;
      padding: ${getButtonPadding};
    }
  }
`

const ButtonText = styled(Text)<{ hasStartIcon: boolean; hasEndIcon: boolean }>`
  margin-left: ${({ hasStartIcon }) => hasStartIcon && `${theme.spacings.xs4}px`};
  margin-right: ${({ hasEndIcon }) => hasEndIcon && `${theme.spacings.xs4}px`};
`

const ContentWrapper = styled(Flex)<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => !isVisible && 0};
  width: 100%;
`

const IconsWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const Styled = {
  Wrapper,
  ButtonText,
  ContentWrapper,
  IconsWrapper,
  Inner,
}
