import styled from 'styled-components'

import { LinkProps } from './types'

import { theme } from 'theme'

const colorTypeMap = {
  dark: theme.colors.neutral.g100,
  currentColor: 'currentColor',
  default: theme.colors.blue.primary,
  premiumBanking: theme.colors.emerald.primary
}

const LinkWrapper = styled.a<Pick<LinkProps, 'colorType' | 'activeColor' | 'inline'>>`
  width: auto;
  display: ${({ inline }) => (inline ? 'inline' : 'inline-flex')};
  cursor: pointer;
  color: ${({ colorType }) => colorTypeMap[colorType]};
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  transition: unset;

  &:hover {
    text-decoration: underline;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus {
    outline: auto;
  }

  &:focus-visible {
    outline: auto;
  }

  &:active {
    color: ${({ colorType, activeColor }) => {
      if (activeColor) {
        return activeColor
      } else {
        return colorType === 'dark' || colorType == 'currentColor'
          ? theme.colors.neutral.g300
          : theme.colors.neutral.g100
      }
    }};
  }
`

const Styled = {
  LinkWrapper,
}

export { Styled }
