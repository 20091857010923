import { ReactNode } from 'react'
import styled from 'styled-components'

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const DataWrapper = styled.div<{ withTitle: string | ReactNode }>`
  flex: 1;

  > :last-child {
    margin-top: ${({ withTitle }) => (withTitle ? '4px' : 0)};
  }
`

const IconWrapper = styled.div`
  cursor: pointer;
`

export const Styled = { InfoWrapper, DataWrapper, IconWrapper }
