import styled from 'styled-components'

import { theme } from 'theme'

export const toastMapping = (toastType: string, variant: 'default' | 'premiumBanking') => {
  if(variant == 'premiumBanking') {
    return toastType == 'info' ||  toastType == 'error' ? { icon: 'baseX24/ic-info-full', color: theme.colors.neutral.g800 } : null
  } else {
    switch (toastType) {
      case 'info': return { icon: 'baseX24/ic-info-full', color: theme.colors.blue.primary }
      case 'error': return { icon: 'baseX24/ic-close-full', color: theme.colors.orange.primary }
      case 'success': return { icon: 'baseX24/ic-check-round-full', color: theme.colors.green.primary }
    }
  }
}

const getToastPadding = (toastType: string, withClose: boolean, variant: 'default' | 'premiumBanking') => {
  const iconTyped = Boolean(toastMapping(toastType, variant))

  const paddingBottom = iconTyped ? '24' : '20'
  const paddingRight = withClose ? '18' : '20'
  const paddingLeft = iconTyped ? '22' : '20'

  return `20px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`
}

const ToastWrapper = styled.div<{ toastType: string; hasTitle: boolean; variant: 'default' | 'premiumBanking' }>`
  display: flex;
  width: 100%;
  padding: ${({ toastType, hasTitle, variant }) => getToastPadding(toastType, hasTitle, variant)};
  background: ${({ toastType, variant }) => 
    variant == 'premiumBanking'
    ? toastType == 'error'
      ? theme.colors.orange.primary
      : theme.colors.neutral.g400
    : theme.colors.neutral.g100
  };
  margin: 0 0 10px 0;
  border-radius: ${theme.radiuses.lg}px;

  &:last-child {
    margin: 0;
  }

  @media (min-width: ${theme.breakpoints.tablet}px) {
    margin: 0 0 20px 0;
  }
`

const CloseIconWrapper = styled.div`
  cursor: pointer;
`

const TypeIconWrapper = styled.div`
  margin-right: 10px;
`

export const Styled = {
  ToastWrapper,
  CloseIconWrapper,
  TypeIconWrapper,
}
