import React, { FC, ReactNode } from 'react'

import { Styled } from './styled'

import { theme } from 'theme'
import { Text } from 'components/atoms/Text'

export interface ToastInfoProps {
  title?: string | ReactNode
  message?: string | ReactNode
  children?: ReactNode
}

export const ToastInfo: FC<ToastInfoProps> = ({ title = '', message, children }) => {
  return (
    <Styled.InfoWrapper>
      <Styled.DataWrapper withTitle={title}>
        {title && (
          <Text sizemob="lg" fontWeight="medium" color={theme.colors.neutral.g800}>
            {title}
          </Text>
        )}
        <Text sizemob="lg" color={theme.colors.neutral.g800}>
          {message}
        </Text>
      </Styled.DataWrapper>
      {children}
    </Styled.InfoWrapper>
  )
}
