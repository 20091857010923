import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import Tippy from '@tippy.js/react'

import { getRightPadding, getWidthSideSize, getHeightSideSize } from './utility'
import { WrapperProps } from './types'

import { Icon } from 'components/atoms/Icon'
import { ToolTip } from 'components/atoms/ToolTip'
import { theme } from 'theme'

const CustomIcon = styled(Icon)<{ disabled?: boolean; variant: 'default' | 'privateBanking' | 'premiumBanking' | 'msbAccount'}>`
  color: ${({ variant }) =>
    variant == 'privateBanking' || variant == 'premiumBanking' ? theme.colors.neutral.g300 : theme.colors.neutral.g400};
  transition: color ${theme.transitions.basic};
  pointer-events: all;
  -webkit-tap-highlight-color: transparent;
  ${({ disabled, variant }) =>
    !disabled &&
    css`
      :hover {
        color: ${() =>
          variant == 'privateBanking' || variant == 'premiumBanking' ? theme.colors.neutral.g500 : theme.colors.neutral.g100};
        cursor: pointer;
      }
    `}
`
const ClearIcon = styled(CustomIcon)<{
  isIconSecond: boolean
}>`
  margin-right: ${({ isIconSecond }) => (isIconSecond ? 5 : 0)}px;
`

const RotatingCustomIcon = styled(Icon)<{ isOpen: boolean; variant: 'default' | 'privateBanking' | 'premiumBanking' | 'msbAccount' }>`
  -webkit-tap-highlight-color: transparent;

  color: ${({ variant }) => variant === 'privateBanking' && theme.colors.neutral.g300};

  svg {
    transition: all ${theme.transitions.basic};
    transform: 0deg;
    ${({ isOpen, variant }) =>
      isOpen &&
      css`
        transform: rotate(180deg);
        color: ${() =>
          variant === 'privateBanking' ? theme.colors.neutral.g500 : theme.colors.neutral.g100};
      `};
  }
`

const CustomTippy = styled(Tippy)`
  margin-top: -6px;
`

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${theme.z_indexes.basic};
  padding-right: ${getRightPadding}px;
  display: ${({ isAllIconsHidden }) => (isAllIconsHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: flex-end;
  width: ${getWidthSideSize}px;
  height: ${getHeightSideSize(false)}px;
  pointer-events: none;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: ${getWidthSideSize}px;
    height: ${getHeightSideSize(true)}px;
  }

  & > div {
    outline: 0;
  }
`

const CustomToolTip = styled(ToolTip)<{ hint: string | ReactNode }>`
  display: block;
  pointer-events: all;

  @media (max-width: ${theme.breakpoints.tablet}px) {
    margin: unset;
  }
`

export const Styled = {
  CustomIcon,
  ClearIcon,
  RotatingCustomIcon,
  CustomTippy,
  Wrapper,
  CustomToolTip,
}
