/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

import React, { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'

import { theme } from 'theme'

export interface DividerProps extends HTMLAttributes<HTMLDivElement> {
  vertical?: boolean
  horizontal?: boolean
}

export const Divider: FC<DividerProps> = ({ vertical = false, horizontal = true, ...rest }) => {
  return (
    <DividerInner vertical={vertical} horizontal={horizontal} data-testid="divider" {...rest} />
  )
}

const DividerInner = styled.div<DividerProps>`
  background-color: ${theme.colors.neutral.g600};
  min-height: ${({ vertical, horizontal }) => (vertical && !horizontal ? '100%' : '1px')};
  min-width: ${({ vertical, horizontal }) => (vertical && !horizontal ? '1px' : '100%')};
`
