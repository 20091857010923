import React, { FC, useRef } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { TextFieldWrapper } from '../TextFieldWrapper'

import { Styled } from './styled'

import { TextFieldWrapperProps } from 'components/molecules/moleculeTypes'

interface TextAreaProps extends Omit<TextFieldWrapperProps, 'children'> {
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

// TODO: перенести в TextField и объединить логику с Input. Убрать TextFieldWrapper из-за его сложности
export const TextArea: FC<TextAreaProps> = ({
  label,
  tooltip,
  disabled,
  readOnly,
  placeholder,
  hasError,
  value,
  size,
  isClearable,
  onClear,
  clearThreshold,
  onFocus,
  onBlur,
  onClick,
  onChange,
  componentRef,
  children,
  rows,
  isAutoResize,
  className,
  hasDropdown,
  isDropdownOpened,
  hasControl,
  variant,
  ...rest
}) => {
  const textFieldProps = {
    fieldChildren: children,
    isTextarea: true,
    variant,
    label,
    tooltip,
    disabled,
    readOnly,
    hasError,
    value,
    size,
    isClearable,
    clearThreshold,
    onFocus,
    onBlur,
    onClick,
    componentRef,
    isAutoResize,
    rows,
    className,
    hasDropdown,
    isDropdownOpened,
    hasControl,
  }

  const scrollBarRef = useRef<HTMLElement>(null)
  const scrollBarInstance = useRef<PerfectScrollbar>(null)

  const handleClear = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    scrollBarRef.current.scrollTop = 0
    onClear && onClear(e)
  }
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!e.target.value && scrollBarRef.current.scrollTop !== 0) {
      scrollBarRef.current.scrollTop = 0
      scrollBarInstance.current.updateScroll()
    }
    onChange && onChange(e)
  }
  return (
    <TextFieldWrapper onClear={handleClear} {...textFieldProps}>
      {({ setRef, handleInputFocus, handleBlur }) => (
        <PerfectScrollbar
          ref={scrollBarInstance}
          containerRef={ref => (scrollBarRef.current = ref)}
        >
          <Styled.TextareaWrapper isAutoResize={isAutoResize} rows={rows} size={size}>
            <TextareaAutosize
              ref={setRef}
              onFocus={handleInputFocus}
              onBlur={handleBlur}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              value={value as string}
              onChange={handleChange}
              spellCheck="false"
              {...rest}
            />
          </Styled.TextareaWrapper>
        </PerfectScrollbar>
      )}
    </TextFieldWrapper>
  )
}

TextArea.defaultProps = {
  label: '',
  tooltip: '',
  disabled: false,
  placeholder: '',
  hasError: false,
  readOnly: false,
  size: 'lg',
  isClearable: false,
  clearThreshold: 0,
  componentRef: null,
  isAutoResize: false,
  rows: 3,
  onClear: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onClick: () => {},
}
