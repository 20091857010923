import Color from 'color'

const alpha = (color: string, level: number) => Color(color).alpha(level).hsl().string()

const darken = (color: string, ratio: number) => Color(color).darken(ratio).hex()

//Брендовый, первичные кнопки
const red = '#E30611'

const redLight = '#FF0032'

//Ссылки
const blue = '#007CFF'

//Заливка фона, текст ошибки негативных действий
const orange = '#EB4A13'

//Заливка фона, текст успешных действий
const green = '#12B23F'

const neutralSecondary = '#202D3D'

//спец раздел premium
const emerald = '#00525D'

const ratio = {
  sm: 0.125,
  md: 0.25,
  hover: 0.12,
  active: 0.24,
} as const

const supportColors = {
  s100: '#7D4594',
  s150: '#9E7DCF',
  s200: '#60549C',
  s250: '#8787FF',
  s300: '#5CBCB6',
  s350: '#1793A4',
  s400: '#036DA9',
  s450: '#6796DE',
  s500: '#5D926B',
  s550: '#70AC41',
  s600: '#AFCF54',
  s650: '#F9CC2F',
  s700: '#C64F8F',
  s750: '#FA7474',
  s800: '#FA5533',
  s850: '#FF9D0A',
} as const

export const colors = {
  //Заголовки, основной текст, фон
  black: '#000000',
  white: '#FFF',
  neutral: {
    translucent: alpha(neutralSecondary, ratio.md),
    g100: '#000000',
    g200: '#2C3135',
    g300: '#626C77',
    //Неактивный текст
    g400: '#969FA8',
    //Рамка поля, обводка
    g500: '#BBC1C7',
    //Разделители, вторичные кнопки
    g600: '#E2E5EB',
    //Текст, заливка фона
    g700: '#F2F3F7',
    //Заливка фона
    g800: '#FFFFFF',
  },
  red: {
    primary: red,
    hover: darken(red, ratio.hover),
    active: darken(red, ratio.active),
    translucent: alpha(red, ratio.sm),
    light: redLight
  },
  blue: { primary: blue, hover: darken(blue, ratio.hover), active: darken(blue, ratio.active) },
  orange: {
    primary: orange,
    hover: darken(orange, ratio.hover),
    active: darken(orange, ratio.active),
  },
  green: { primary: green, hover: darken(green, ratio.hover), active: darken(green, ratio.active) },
  yellow: {
    autofill: '#FBFFC4',
  },
  emerald: {
    primary: emerald,
    hover: '#4D868E',
    active: '#1A636D'
  },
  transparent: 'transparent',
  supportColors,
} as const
