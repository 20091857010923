import styled from 'styled-components'
import InlineSVG from 'react-inlinesvg'

import { theme } from 'theme'

const Wrapper = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
`
const Svg = styled(InlineSVG)`
  transition: ${theme.transitions.basic};
`

export const Styled = {
  Wrapper,
  Svg,
}
