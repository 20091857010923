import { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import { Text } from '../Text'

import { theme } from 'theme'
import { getWidthSideSize } from 'components/atoms/InputControl/utility'
import { InputControlProps } from 'components/atoms/InputControl/index'

const getLabelSize =
  (themeSizes: typeof theme.fonts.font_sizes.par | typeof theme.fonts.line_heights.par) =>
  ({
    size,
    isInside,
    hasInputValue,
    isFocused
  }: {
    size: 'xl' | 'lg' | 'md'
    isInside: boolean
    hasInputValue: boolean
    isFocused: boolean
  }): number => {
    return !isInside || hasInputValue || isFocused ? themeSizes.md : themeSizes[size]
  }

type LabelText = {
  hasInputValue: boolean
  isFocused: boolean
  isInside: boolean
  disabled: boolean
  size: 'xl' | 'lg' | 'md'
  variant?: 'default' | 'privateBanking' | 'premiumBanking' | 'msbAccount'
}

const getWrapperHeight = ({ isInside, size, hasInputValue, isFocused }: LabelText) => {
  if (!isInside) return 'auto'

  const lineHeight = hasInputValue || isFocused
    ? theme.fonts.line_heights.par.md
    : theme.fonts.line_heights.par[size]

  return `${lineHeight}px`
}

const LabelText = styled(Text)<LabelText>`
  font-size: ${({ hasInputValue, isFocused }) =>
    hasInputValue || isFocused ? theme.fonts.font_sizes.par.sm : theme.fonts.font_sizes.par.lg}px;
  line-height: ${({ hasInputValue, isFocused }) =>
    hasInputValue || isFocused ? theme.fonts.line_heights.par.sm : theme.fonts.line_heights.par.lg}px;
  font-weight: ${theme.fonts.font_weights.regular};
  color: ${({ isFocused, variant, disabled }) =>
    variant == 'privateBanking'
      ? isFocused
        ? theme.colors.neutral.g500
        : theme.colors.neutral.g300
      : variant == 'premiumBanking'
        ? isFocused
          ? theme.colors.neutral.g400
          : disabled 
            ? theme.colors.neutral.g500 
            : theme.colors.neutral.g300
      : isFocused
      ? theme.colors.neutral.g100
      : theme.colors.neutral.g400};
  transition: all ${theme.transitions.basic};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    font-size: ${getLabelSize(theme.fonts.font_sizes.par)}px;
    line-height: ${getLabelSize(theme.fonts.line_heights.par)}px;
    font-weight: ${({ isInside }) => !isInside && theme.fonts.font_weights.regular};
    color: ${({ isInside, isFocused, variant, disabled }) =>
      variant == 'privateBanking'
        ? theme.colors.neutral.g400
        : variant == 'premiumBanking'
          ? disabled
           ? theme.colors.neutral.g500
           : theme.colors.neutral.g400
        : isInside && !isFocused
        ? theme.colors.neutral.g300
        : theme.colors.neutral.g100};

    ${({ isInside }) =>
      !isInside &&
      css`
        text-overflow: clip;
        white-space: normal;
        overflow: auto;
      `}
  }
`

const LabelDesktop = styled.label`
  display: flex;
  width: 100%;
`

const LabelMobile = styled.label`
  display: flex;
  width: 100%;
`

type LabelWrapperProps = {
  size: 'xl' | 'lg' | 'md'
  isInside: boolean
  isClearIconHidden: boolean
  hasInputValue: boolean
  hasIcon: boolean
  hasScroll: boolean
  disabled: boolean
  readOnly: boolean
  isFocused: boolean
  controlChildren: boolean
} & HTMLAttributes<HTMLDivElement> &
  InputControlProps

const labelWrapperMargins = (isMobile: boolean) => css<LabelWrapperProps>`
  margin-left: ${({
    size,
    isClearIconHidden,
    hasIcon,
    hasDropdown,
    asPassword,
    hasEndIcon,
    tooltip,
    isInside,
  }) =>
    isInside
      ? getWidthSideSize(
          {
            hasDropdown,
            hasAdditionalItem: asPassword,
            tooltip,
            hasEndIcon,
            isClearIconHidden,
            size,
            isMobile: isMobile,
            icon: hasIcon,
          },
          'left',
        )
      : 0}px;
  margin-right: ${({
    size,
    hasIcon,
    hasDropdown,
    asPassword,
    hasEndIcon,
    isClearIconHidden,
    tooltip,
    isInside,
    controlChildren,
  }) =>
    isInside
      ? getWidthSideSize(
          {
            hasDropdown,
            hasAdditionalItem: asPassword || controlChildren,
            tooltip,
            hasEndIcon,
            isClearIconHidden,
            size,
            isMobile: isMobile,
            icon: hasIcon,
          },
          'right',
        )
      : 0}px;
`

const LabelWrapper = styled.div<LabelWrapperProps>`
  position: absolute;
  top: ${({ hasInputValue, isFocused }) => (hasInputValue || isFocused ? `${theme.border_sizes.basic}px` : '14px')};
  right: 0;
  left: ${theme.border_sizes.basic}px;
  z-index: ${theme.z_indexes.basic};
  display: flex;
  align-items: center;
  height: ${({ hasInputValue, isFocused }) =>
    hasInputValue || isFocused ? theme.fonts.line_heights.par.md : theme.fonts.line_heights.par.lg}px;
  ${labelWrapperMargins(true)}
  pointer-events: none;
  background-color: ${({ isInside, hasInputValue, disabled, readOnly, variant }) =>
    variant == 'privateBanking'
      ? theme.colors.transparent
      : isInside && hasInputValue && !disabled && !readOnly
      ? theme.colors.white
      : theme.colors.transparent};
  transition: background-color 0ms ${theme.transitions.basic}, top ${theme.transitions.basic},
    left ${theme.transitions.basic}, height ${theme.transitions.basic},
    padding-top ${theme.transitions.basic};

  @media (min-width: ${theme.breakpoints.tablet}px) {
    position: ${({ isInside }) => (isInside ? null : 'static')};
    top: ${({ isInside, hasInputValue, isFocused }) =>
      (isInside && hasInputValue) || (isInside && isFocused) ? `${theme.border_sizes.basic}px` : '24px'};
    height: ${getWrapperHeight};
    padding-top: ${({ isInside, hasInputValue, isFocused }) => ((isInside && hasInputValue) || (isInside && isFocused) ? '8px' : '0')};
    box-sizing: content-box;
    ${labelWrapperMargins(false)}
    margin-bottom: ${({ isInside }) => !isInside && `${theme.spacings.xs3}px`};
    pointer-events: ${({ isInside }) => (isInside ? 'none' : 'auto')};
  }
`

export const Styled = {
  LabelText,
  LabelDesktop,
  LabelMobile,
  LabelWrapper,
}
