import styled from 'styled-components'

import { theme } from 'theme'

const Wrapper = styled.div`
  display: flex;
  margin: 16px 0 0 0;

  > :first-child {
    background: ${theme.colors.neutral.g800};
    border-radius: ${theme.radiuses.lg}px;
  }

  > :last-child {
    background: ${theme.colors.neutral.g800};
    border-radius: ${theme.radiuses.lg}px;
    margin: 0 0 0 12px;
  }
`

export const Styled = { Wrapper }
