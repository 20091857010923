import styled from 'styled-components'
import Tippy from '@tippy.js/react'

import { Text } from '../Text'

import { Icon } from 'components/atoms/Icon'
import { theme } from 'theme'

const ToolTipWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

const StyledText = styled(Text)<{ noIcon?: boolean }>`
  padding-right: ${({ noIcon }) => (noIcon ? 0 : '4px')};
`
const Wrapper = styled.div<{ noIcon?: boolean }>`
  padding-right: ${({ noIcon }) => (noIcon ? 0 : '4px')};
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  border-radius: 50%;
  color: ${theme.colors.neutral.g400};

  svg {
    transition: ${theme.transitions.basic};
  }
`

const InnerWrapper = styled.div<{ asWrapper: boolean }>`
  display: flex;
  outline: none;

  :hover ${StyledIcon} {
    color: ${theme.colors.black};
  }

  ${({ asWrapper }) =>
    !asWrapper &&
    `@media (max-width: ${theme.breakpoints.tablet}px) {
      width: 32px;
      height: 32px;
      justify-content: center;
     `}
`
const HintTextWrapper = styled(Text)`
  word-break: normal;
  color: ${theme.colors.white};
`

const StyledTippy = styled(Tippy)<{theme: string}>`
  background: ${({ theme }) => theme ? theme : theme.colors.black};

  &[x-placement^='top'] {
    .tippy-arrow {
      border-top-color: ${({ theme }) => theme ? theme : theme.colors.black};
    }
  }

  &[x-placement^='right'] {
    .tippy-arrow {
      border-right-color: ${({ theme }) => theme ? theme : theme.colors.black};
    }
  }

  &[x-placement^='left'] {
    .tippy-arrow {
      border-left-color: ${({ theme }) => theme ? theme : theme.colors.black};
    }
  }

  &[x-placement^='bottom'] {
    .tippy-arrow {
      border-bottom-color: ${({ theme }) => theme ? theme : theme.colors.black};
    }
  }
`

export const Styled = {
  ToolTipWrapper,
  StyledText,
  Wrapper,
  InnerWrapper,
  StyledIcon,
  HintTextWrapper,
  StyledTippy,
}
