import styled, { keyframes } from 'styled-components'

import { Flex } from '../Primitives'

import { theme } from 'theme'

const Wrapper = styled(Flex)<{ filled: boolean }>`
  border: ${`${theme.borders.basic} ${theme.colors.neutral.g600}`};
  background-image: ${({ filled }) =>
    filled &&
    `linear-gradient(
    90deg,
    ${theme.colors.neutral.g600} 0%,
    ${theme.colors.neutral.g700} 50%,
    ${theme.colors.neutral.g600} 89%
  )`};
  border-radius: ${theme.radiuses.lg}px;
  padding: 20px;
`

const radiusMap = (height: number, radius: number) => {
  switch (height) {
    case 24:
    case 20:
      return 6
    case 16:
      return 4
    case 8:
      return 2
    default:
      return radius
  }
}

const shine = keyframes`
  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`
const Rectangle = styled.div<{ height: string | number; radius: string | number }>`
  width: 100%;
  height: ${({ height }) => height}px;
  border-radius: ${({ radius, height }) => radiusMap(Number(height), Number(radius))}px;
  animation: ${shine} 2s infinite linear;
  background-color: ${theme.colors.neutral.g600};
`

const Round = styled.div<{ height: number | string }>`
  display: inline-flex;
  border-radius: ${theme.radiuses.round}px;
  min-height: ${({ height }) => height}px;
  min-width: ${({ height }) => height}px;
  max-height: ${({ height }) => height}px;
  max-width: ${({ height }) => height}px;
  animation: ${shine} 2s infinite linear;
  background-color: ${theme.colors.neutral.g600};
`
const Styled = {
  Wrapper,
  Round,
  Rectangle,
}

export { Styled }
