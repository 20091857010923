import React, { FC, HTMLAttributes } from 'react'

import { Styled } from './styled'

import { theme } from 'theme'

export interface ToolTipProps extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  hint: React.ReactNode
  placement?: 'top' | 'bottom' | 'right' | 'left'
  asWrapper?: boolean
  noIcon?: boolean
  /** Включает/выключает интерактивность всплывающего тултипа */
  interactive?: boolean
  /** Уcтанавливает z-index на корневом узле тултипа */
  zIndex?: number
  /** Bg color контейнера тултипа */
  theme?: string
}

export const ToolTip: FC<ToolTipProps> = ({
  children,
  hint,
  placement,
  noIcon,
  asWrapper,
  interactive,
  zIndex,
  theme,
  ...rest
}) => {
  const tippyContent = (
    <Styled.HintTextWrapper size="md">
      {hint}
    </Styled.HintTextWrapper>
  )
  const isAsWrapper = !!asWrapper
  return (
    <Styled.ToolTipWrapper {...rest} data-testid="tooltip">
      {!isAsWrapper && children && <Styled.StyledText>{children}</Styled.StyledText>}
      <Styled.StyledTippy
        interactive={interactive}
        arrow
        maxWidth="288px"
        placement={placement}
        content={tippyContent}
        zIndex={zIndex}
        theme={theme}
      >
        <Styled.InnerWrapper asWrapper={asWrapper}>
          {isAsWrapper ? <Styled.Wrapper noIcon={noIcon} >{children}</Styled.Wrapper> : false}
          {!noIcon && <Styled.StyledIcon icon={'baseX16/tooltip'} withWrapper />}
        </Styled.InnerWrapper>
      </Styled.StyledTippy>
    </Styled.ToolTipWrapper>
  )
}

ToolTip.defaultProps = {
  placement: 'top',
  interactive: true,
  zIndex: 9999,
  theme: theme.colors.black
}
