import React, { ReactNode } from 'react'

import { Options, ToastStorage } from './types'

export const ToastContext = React.createContext<{
  toast: (type: string, title: string | ReactNode, message: string | ReactNode, options?: Options) => void
  removeToast: (index: string) => void
  toastStorage: ToastStorage
}>({
  toast: null,
  removeToast: null,
  toastStorage: null,
})

// @deprecated old api
export const OldApiContext = React.createContext<{
  globalOnAccept: (event: React.MouseEvent) => void
  globalOnReject: (event: React.MouseEvent) => void
  setGlobalActions: (acceptFn: Options['onAccept'], rejectFn: Options['onReject']) => void
}>({
  globalOnAccept: null,
  globalOnReject: null,
  setGlobalActions: null,
})
