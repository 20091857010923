import { theme } from 'theme'

const {
  fonts: { bottom_margins, font_sizes, line_heights },
} = theme

export const fontSizes = {
  1: font_sizes.header.xl,
  2: font_sizes.header.lg,
  3: font_sizes.header.md,
  3.1: font_sizes.header.md1,
  4: font_sizes.header.sm,
  4.1: font_sizes.header.sm1
}

export const lineHeights = {
  1: line_heights.header.xl,
  2: line_heights.header.lg,
  3: line_heights.header.md,
  3.1: line_heights.header.md1,
  4: line_heights.header.sm,
  4.1: line_heights.header.sm1
}

export const bottomMargins = {
  1: bottom_margins.lg,
  2: bottom_margins.lg,
  3: bottom_margins.lg,
  4: bottom_margins.md,
}
