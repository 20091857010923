export const fonts = {
  font_sizes: {
    header: { sm: 20, sm1: 20, md: 24, md1: 24, lg: 32, xl: 40, xl1: 40 },
    par: { sm: 12, md: 14, lg: 17, xl: 20 },
  },
  font_family: '"MTS Sans", "Arial", "Helvetica", sans-serif',
  font_weights: { regular: 400, medium: 500, bold: 700, black: 900 },
  line_heights: {
    header: { sm: 24, sm1: 28, md: 28, md1: 32, lg: 36, xl: 44, xl1: 48 },
    par: { sm: 16, md: 20, lg: 24, xl: 24 },
  },
  bottom_margins: { sm: 8, md: 16, lg: 24, xl: 32 },
} as const
