import React, { FC } from 'react'
import styled from 'styled-components'

export interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  justify?: string
  alignItems?: string
  alignContent?: string
  wrap?: string
  flex?: string
  self?: string
  direction?: string
  fillHeight?: boolean
  fillWidth?: boolean
  /**
   * паддинг
   */
  p?: string
}

const Inner = styled.div<FlexProps>`
  display: flex;
  flex: ${({ flex = 'unset' }) => flex};
  justify-content: ${({ justify = 'unset' }) => justify};
  align-content: ${({ alignContent = 'unset' }) => alignContent};
  align-items: ${({ alignItems = 'unset' }) => alignItems};
  flex-wrap: ${({ wrap = 'unset' }) => wrap};
  align-self: ${({ self = 'unset' }) => self};
  height: ${({ fillHeight = false }) => (fillHeight ? '100%' : 'unset')};
  width: ${({ fillWidth = false }) => (fillWidth ? '100%' : 'unset')};
  flex-direction: ${({ direction = 'unset' }) => direction};
  padding: ${({ p }) => p};
`

export const Flex: FC<FlexProps> = ({ ...rest }) => {
  return <Inner data-testid="flexbox" {...rest} />
}
