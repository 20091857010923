/* eslint-disable no-magic-numbers */
import React, { FC } from 'react'

import { TextFieldProps } from 'components/molecules/moleculeTypes'
import { TextField } from 'components/molecules/TextField'

export const PhoneInput: FC<Partial<TextFieldProps>> = ({
  children,
  prefix,
  mask,
  onChange,
  onFocus,
  onClick,
  onKeyDown,
  ...rest
}) => {
  const handlerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= prefix.length) {
      e.target.value = prefix
    }
    onChange && onChange(e)
  }
  const focusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.selectionStart < prefix.length) {
      e.target.setSelectionRange(3, 3)
    }
    if (e.target && e.target.value.substr(0, prefix.length) !== prefix) {
      e.target.value = prefix
    }
    onFocus && onFocus(e)
  }
  const clickHandler = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (!(e.target as HTMLInputElement).selectionEnd && (e.target as HTMLInputElement).selectionStart < prefix.length) {
      (e.target as HTMLInputElement).setSelectionRange(3, 3)
    }
    onClick && onClick(e)
  }
  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.target as HTMLInputElement).selectionStart < prefix.length && e.keyCode !== 8 && e.keyCode !== 46) {
      (e.target as HTMLInputElement).setSelectionRange(3, 3)
    }
    onKeyDown && onKeyDown(e)
  }
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = e.clipboardData
    const escapedPhone = clipboardData.getData('Text').replace(/[\s()+-]/g, '')

    if (escapedPhone.length >= 11 && (escapedPhone.charAt(0) === '7' || escapedPhone.charAt(0) === '8')) {
      (e.target as HTMLInputElement).value = escapedPhone.substr(1)
    }
  }

  return (
    <TextField
      keepCharPositions
      showMask
      mask={mask}
      placeholder={prefix}
      clearThreshold={prefix.length + 1}
      type="tel"
      inputMode="tel"
      onChange={handlerChange}
      onFocus={focusHandler}
      onClick={clickHandler}
      onKeyDown={keyDownHandler}
      onPaste={handlePaste}
      {...rest}
    >
      {children}
    </TextField>
  )
}

PhoneInput.defaultProps = {
  prefix: '+7 ',
  mask: ['+', '7', ' ', /[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  onChange: null,
  onFocus: null,
  onClick: null,
  onKeyDown: null,
}
