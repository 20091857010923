// Если что-то меняете в Button, то проверяйте, что ничего не сломалось в ButtonGroup

import React, { FC } from 'react'

import { textSizes, defaultIconSizes, buttonColorTypes } from './config'
import { Styled } from './styled'

import { Icon } from 'components/atoms/Icon'

export interface ButtonProps
  extends Omit<React.AllHTMLAttributes<HTMLButtonElement>, 'size' | 'as'> {
  size?: keyof typeof textSizes
  sizemob?: keyof typeof textSizes
  variant?: 'primary' | 'secondary' | 'outline' | 'ghost'
  colorType?: keyof typeof buttonColorTypes
  disabled?: boolean
  fluid?: boolean
  fluidmob?: boolean
  rounded?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  isLoading?: boolean
  isDone?: boolean
  justify?: 'center' | 'space-between' | 'space-around'
  as?: React.ElementType
}

export const Button: FC<ButtonProps> = ({
  children,
  size,
  sizemob,
  colorType,
  variant,
  disabled,
  fluid,
  fluidmob,
  rounded,
  startIcon,
  endIcon,
  isLoading,
  isDone,
  justify,
  ...rest
}) => {
  const iconSize = defaultIconSizes[size]
  const isButtonActive = !disabled && !isLoading && !isDone
  //const isDefaultButtonType = colorType === buttonStates.default

  const addActiveToIOS = () => {}

  return (
    <Styled.Wrapper
      data-testid="button"
      size={size}
      sizemob={sizemob || size}
      variant={variant}
      colorType={colorType}
      disabled={disabled}
      fluid={fluid}
      fluidMob={fluidmob || fluid}
      rounded={rounded}
      hasStartIcon={!!startIcon}
      hasEndIcon={!!endIcon}
      hasText={!!children}
      isLoading={isLoading}
      isDone={isDone}
      tabIndex={isButtonActive ? 0 : 1}
      {...rest}
    >
      {/*! Styled.Inner onTouchStart={addActiveToIOS}  для работы :active на IOS */}
      <Styled.Inner
        onTouchStart={addActiveToIOS}
        alignItems="center"
        justify="center"
        tabIndex={-1}
      >
        {(isLoading || isDone) && (
          <Styled.IconsWrapper alignItems="center" justify="center">
            {isLoading && <Icon icon={`baseX${iconSize}/loading`} />}
            {isDone && <Icon icon={`baseX${iconSize}/check`} />}
          </Styled.IconsWrapper>
        )}

        <Styled.ContentWrapper
          alignItems="center"
          justify={justify}
          isVisible={!isLoading && !isDone}
        >
          {startIcon}
          {children && (
            <Styled.ButtonText
              size={textSizes[size]}
              sizemob={textSizes[sizemob || size]}
              color="inherit"
              fontWeight="medium"
              hasStartIcon={!!startIcon}
              hasEndIcon={!!endIcon}
            >
              {children}
            </Styled.ButtonText>
          )}
          {endIcon}
        </Styled.ContentWrapper>

        {/*  {isButtonActive && isDefaultButtonType && <Ripple />} */}
      </Styled.Inner>
    </Styled.Wrapper>
  )
}

Button.defaultProps = {
  sizemob: null,
  size: 'md',
  variant: 'primary',
  colorType: 'default',
  disabled: false,
  fluid: false,
  fluidmob: false,
  rounded: false,
  startIcon: null,
  endIcon: null,
  isLoading: false,
  isDone: false,
  justify: 'center',
}
