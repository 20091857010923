import { HTMLProps } from 'react'
import styled, { css } from 'styled-components'

import * as config from './config'
import { HeadingProps } from './types'

import { theme } from 'theme'

const { font_weights, font_sizes, line_heights, bottom_margins } = theme.fonts

const { bottomMargins, lineHeights, fontSizes } = config

type WrapperProps = Pick<
  HeadingProps,
  'h' | 'capitalize' | 'align' | 'fontWeight' | 'noMargin' | 'size' | 'sizemob'
> & { $color?: HeadingProps['color'] } &
  HTMLProps<HTMLDivElement>

const makeMobileStyle = css<Pick<WrapperProps, 'sizemob' | 'noMargin'>>`
  @media (max-width: ${theme.breakpoints.sm - 1}px) {
    font-size: ${({ sizemob }) => font_sizes.header[sizemob]}px;
    line-height: ${({ sizemob }) => line_heights.header[sizemob]}px;
    margin-bottom: ${({ sizemob, noMargin }) =>
      noMargin ? '0' : sizemob === 'sm' ? bottom_margins.md : bottom_margins.lg}px;
  }
`

const marginMap = ({ h, noMargin, size }: Pick<WrapperProps, 'h' | 'noMargin' | 'size'>) => {
  if (noMargin) {
    return 0
  } else {
    return size ? (size === 'sm' ? bottom_margins.md : bottom_margins.lg) : bottomMargins[h]
  }
}

const Wrapper = styled.div<WrapperProps>`
  margin-top: 0;
  margin-bottom: ${marginMap}px;
  font-family: ${theme.fonts.font_family};
  font-weight: ${({ fontWeight }) => font_weights[fontWeight] || font_weights.bold};
  font-size: ${({ h, size }) => font_sizes.header[size] || fontSizes[h]}px;
  line-height: ${({ h, size }) => line_heights.header[size] || lineHeights[h]}px;
  letter-spacing: 0;
  text-align: ${({ align }) => align};
  text-transform: ${({ capitalize }) => (capitalize ? 'uppercase' : 'none')};
  color: ${({ $color }) => $color};

  ${({ sizemob }) => sizemob && makeMobileStyle}
`

export const Styled = {
  Wrapper,
}
