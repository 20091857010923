import { colors } from './colors'
import { fonts } from './fonts'
const mobSize = 320

const borderSizes = {
  basic: 2,
} as const

const containerGaps = { mob: 16, mobLg: 24, tablet: 32, sm: 32, md: 48, lg: 52 } as const

const theme = {
  colors,
  fonts,
  opacities: {
    op100: 100,
    op80: 80,
    op64: 64,
    op52: 52,
    op40: 40,
    op32: 32,
    op24: 24,
    op16: 16,
    op12: 12,
    op8: 8,
    op4: 4,
    op09: 0.9,
  },
  spacings: {
    xs5: 2,
    xs4: 4,
    xs3: 8,
    xs2: 12,
    xs: 16,
    sm: 24,
    md: 32,
    lg: 40,
    xl: 52,
    xl2: 64,
    xl3: 80,
    xl4: 96,
  },
  transitions: {
    basic: '200ms ease',
    fast: '125ms ease-in-out',
  },
  icon_sizes: {
    xs: '8',
    sm: '16',
    md: '24',
    lg: '32',
    xl: '52',
  },
  inputControl_paddings: {
    xl: 18,
    lg: 16,
    md: 12,
    mobile: 14,
  },
  border_sizes: borderSizes,
  borders: {
    basic: `${borderSizes.basic}px solid`,
  },
  scrollbar_width: 19,
  radiuses: {
    xl: 10,
    lg: 8,
    md: 6,
    sm: 4,
    xs: 2,
    round: 5000,
  },
  breakpoints: {
    none: 0,
    mob: 320,
    mobLg: 600,
    tablet: 736,
    sm: 1024,
    md: 1280,
    lg: 1440,
    uber: 1800,
  },
  layout: {
    lg: {
      width: 1292,
      padding: 32,
    },
    md: {
      width: 1152,
      padding: 32,
    },
    sm: {
      width: 880,
      padding: 32,
    },
    tablet: {
      width: 672,
      padding: 32,
    },
    mobLg: {
      width: 568,
      padding: 24,
    },
    mob: {
      width: 288,
      padding: 16,
    },
  },
  z_indexes: {
    zero: 0,
    high: 99999,
    negative: -1,
    basic: 1,
    med: 5000,
  },
  container_gaps: containerGaps,
  min_width: mobSize - containerGaps.mob * 2,
  sizes: {
    lesser: { xl: 40, lg: 32, md: 24, sm: 20, xs: 16 },
    greater: { xl: 72, lg: 52, md: 44, sm: 32 },
  },
  shadows: {
    cardDefault: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)',
    cardHover: '0px 9px 10px rgba(0, 0, 0, 0.08), 0px 0px 8px rgba(0, 0, 0, 0.08)',
    cardActive: '0px 0px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08)',
    dropdown: '0px 20px 30px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.12)',
    modal: '0px 40px 44px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)',
    buttonDefault: '0px 0px 8px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.1)',
    buttonHoverFocus: '0px 0px 8px rgba(0, 0, 0, 0.08), 0px 9px 10px rgba(0, 0, 0, 0.08)',
    buttonActive: '0px 6px 12px rgba(0, 0, 0, 0.08), 0px 0px 8px rgba(0, 0, 0, 0.08)',
  },
} as const

export { theme }
