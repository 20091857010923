import React, { FC } from 'react'

import { Styled as TextStyled } from '../Text/styled'

import { Styled } from './styled'
import { LinkProps } from './types'

const Link: FC<LinkProps> = ({ colorType, activeColor, inline, linkRef, size, sizemob, children, ...rest }) => {
  let linkChildren = children
  if (!inline) {
    if (typeof children === 'string') {
      linkChildren = (
        <TextStyled.Wrapper size={size} sizemob={sizemob} color={'inherit'}>
          {children}
        </TextStyled.Wrapper>
      )
    } else if (Array.isArray(children)) {
      linkChildren = React.Children.map(linkChildren, child => {
        if (typeof child === 'string') {
          return <TextStyled.Wrapper color={'inherit'}>{child}</TextStyled.Wrapper>
        }
        return child
      })
    }
  }
  return (
    <Styled.LinkWrapper
      ref={linkRef}
      inline={inline}
      colorType={colorType}
      data-testid="link"
      activeColor={activeColor}
      {...rest}
    >
      {linkChildren}
    </Styled.LinkWrapper>
  )
}

Link.defaultProps = {
  colorType: 'default',
  inline: true,
}

export { Link }
