import React, { FC, ImgHTMLAttributes } from 'react'
import styled from 'styled-components'

import { theme } from 'theme'

const Picture = styled.picture<{ width: string; height: string }>`
  img {
    display: block;
    background-color: ${theme.colors.neutral.g600};
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : '100%')};
    object-fit: cover;
  }
`

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  width?: string
  height?: string
  imgClassName?: string
  /**
   * общий путь к директории для source, к которому подставляется ext
   */
  imgListBaseUrl?: string
  /**
   * массив с атрибутами каждого source
   */
  imgList?: Array<{
    /**
     *  подставляется в медиавыражение, которое веб-браузер будет анализировать для выбора картинки
     *
     */
    minWidth?: number
    /**
     * расширение  изображения
     * @example ext: 'jpg'
     */
    ext?: string
    /**
     * используется для предоставления списка возможных изображений в зависимости от размера
     * @example url: "logo-768.png 768w, logo-768-1.5x.png 1.5x"
     */
    url?: string
    /**
     * MIME-тип данных, указанных в url
     */
    type?: string
  }>
}

export const Image: FC<ImageProps> = ({
  imgList,
  width,
  height,
  imgListBaseUrl,
  imgClassName,
  className,
  ...rest
}) => {
  return (
    <Picture width={width} height={height} className={className}>
      {imgList &&
        imgList.map(({ minWidth, url, ext, type }, idx) => (
          <source
            key={idx}
            type={type}
            media={`(min-width: ${minWidth}px)`}
            srcSet={url ? url : `${imgListBaseUrl}.${ext || 'webp'}`}
          />
        ))}
      <img {...rest} className={imgClassName} />
    </Picture>
  )
}
