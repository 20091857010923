import React, { ReactNode, ReactNodeArray, useMemo, useCallback } from 'react'

import { ToastContent } from './organisms/ToastContent'
import { ToastContext, OldApiContext } from './context'
import { useToastState, useOldToastApi } from './hooks'
import { Options } from './types'

export const ToastProvider = ({
  children,
  className,
}: {
  children: ReactNode | ReactNodeArray
  className?: string
}) => {
  const { toastStorage, removeToast, addToast } = useToastState()
  const oldApiContextValue = useOldToastApi()
  const appendToast = useCallback(
    (type: string, title: string | ReactNode, message: string | ReactNode, options?: Options) => {
      addToast({ type, title, message }, options)
    },
    [],
  )
  const contextValue = useMemo(
    () => ({
      toast: appendToast,
      removeToast,
      toastStorage,
    }),
    [addToast, removeToast, toastStorage],
  )

  return (
    <ToastContext.Provider value={contextValue}>
      <OldApiContext.Provider value={oldApiContextValue}>
        <ToastContent toastStorage={toastStorage} className={className} />
        {children}
      </OldApiContext.Provider>
    </ToastContext.Provider>
  )
}
