import React, { memo, useContext } from 'react'

import { OldApiContext, ToastContext } from '../../context'

import { Styled, toastMapping } from './styled'

import { Options, ToastData } from 'components/organisms/Toast/types'
import { ToastInfo } from 'components/organisms/Toast/atoms/ToastInfo'
import { ToastActions } from 'components/organisms/Toast/molecules/ToastActions'
import { Icon } from 'components/atoms/Icon'
import { theme } from 'theme'

interface ToastItemProps {
  id: string
  toast: {
    data: ToastData
    options: Options
  }
}

export const ToastItem: React.FC<ToastItemProps> = memo(({ toast, id }) => {
  const { removeToast } = useContext(ToastContext)
  const { globalOnAccept, globalOnReject } = useContext(OldApiContext)
  const { data: toastData, options: toastOptions } = toast
  const iconType = toastMapping(toastData.type, toastOptions.variant)
  const isActionsAvailable =
    globalOnAccept || globalOnReject || toastOptions.onAccept || toastOptions.onReject

  return (
    <Styled.ToastWrapper toastType={toastData.type} variant={toastOptions.variant} hasTitle={Boolean(toastData.title)}>
      {toastData.title && iconType && (
        <Styled.TypeIconWrapper>
          <Icon icon={iconType.icon} color={iconType.color} />
        </Styled.TypeIconWrapper>
      )}
      <ToastInfo title={toastData.title} message={toastData.message}>
        {toastOptions.withActions && isActionsAvailable && (
          <ToastActions
            toastId={id}
            onAccept={toastOptions.onAccept || globalOnAccept}
            onReject={toastOptions.onReject || globalOnReject}
          />
        )}
        {!toastOptions.withActions && toastOptions.buttons && toastOptions.buttons(id)}
      </ToastInfo>
      {!toastOptions.withActions && toastOptions.withClose ? (
        <Styled.CloseIconWrapper>
          <Icon
            icon="baseX24/ic-close-full"
            color={theme.colors.neutral.g800}
            onClick={() => removeToast(id)}
          />
        </Styled.CloseIconWrapper>
      ) : null}
    </Styled.ToastWrapper>
  )
})
