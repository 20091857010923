import React, { FC } from 'react'

import { Styled } from './styled'

import { TextProps } from 'components/atoms/atomsTypes'
import { DSLParser } from 'utils'
import { theme } from 'theme'

export const Text: FC<TextProps> = ({ textRef, children, fontWeight, color, ...restProps }) => {
  return (
    <Styled.Wrapper ref={textRef} weight={fontWeight} data-testid="text" $color={color} {...restProps}>
      {typeof children === 'string' ? DSLParser(children) : children}
    </Styled.Wrapper>
  )
}

Text.defaultProps = {
  size: 'lg',
  sizemob: null,
  align: 'left',
  fontWeight: 'regular',
  as: 'div',
  color: theme.colors.neutral.g100,
  capitalize: false,
  resetLineHeight: false,
}
