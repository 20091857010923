import { Link } from 'components/atoms/Link'
import { Divider } from 'components/atoms/Divider'
import { Heading } from 'components/atoms/Heading'

export const DSLRules = [
  {
    token: 'link',
    component: Link,
    content: true,
    defaultProps: {
      target: '_self',
    },
    props: [
      {
        name: 'href',
        required: true,
      },
      {
        name: 'target',
        content: ['_blank', '_self', '_parent', '_top'],
      },
    ],
  },
  {
    token: 'hr',
    component: Divider,
  },
  {
    token: 'h1',
    component: Heading,
    content: true,
    defaultProps: {
      h: '1',
    },
  },
  {
    token: 'h2',
    component: Heading,
    content: true,
    defaultProps: {
      h: '2',
    },
  },
  {
    token: 'h3',
    component: Heading,
    content: true,
    defaultProps: {
      h: '3',
    },
  },
  {
    token: 'h4',
    component: Heading,
    content: true,
    defaultProps: {
      h: '4',
    },
  },
  {
    token: 'h5',
    component: Heading,
    content: true,
    defaultProps: {
      h: '5',
    },
  },
  {
    token: 'br',
    component: 'br',
  },
  {
    token: 'medium',
    component: 'b',
    content: true,
  },
  {
    token: 'bold',
    component: 'strong',
    content: true,
  },
  {
    token: 'sup',
    component: 'sup',
    content: true,
  },
  {
    token: 'sub',
    component: 'sub',
    content: true,
  },
]
