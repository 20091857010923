import { useState, useCallback, useEffect, useContext } from 'react'
import { nanoid } from 'nanoid'

import { ToastContext } from './context'
import { Options, ToastData, ToastStorage } from './types'

const TOAST_DELAY = 3000

type onAcceptFn = Options['onAccept']
type onRejectFn = Options['onReject']

export const useToast = () => useContext(ToastContext)

export const useToastState = () => {
  const [toastStorage, setToastStorage] = useState<ToastStorage>({})

  const addToast = useCallback((data: ToastData, options: Options = {}) => {
    setToastStorage(prevState => ({ ...prevState, [nanoid()]: { data, options } }))
  }, [])

  const removeToast = useCallback((id: string) => {
    setToastStorage(prevState =>
      Object.keys(prevState).reduce((result, currentId) => {
        return currentId !== id ? { ...result, [currentId]: prevState[currentId] } : result
      }, {}),
    )
  }, [])

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>
    const toastIds = Object.keys(toastStorage)

    if (toastIds.length > 0) {
      const lastToastId = toastIds[0]
      const lastToast = toastStorage[lastToastId]

      if (!lastToast.options.withClose && !lastToast.options.withActions && !lastToast.options.buttons) {
        timerId = setTimeout(
          () => removeToast(lastToastId),
          lastToast.options.timeout || TOAST_DELAY,
        )
      }
    }
    return () => clearTimeout(timerId)
  }, [toastStorage])

  return {
    toastStorage,
    addToast,
    removeToast,
  }
}

//@deprecated
export const useOldToastApi = () => {
  const [[globalOnAccept, globalOnReject], setActions] = useState<[onAcceptFn, onRejectFn]>([
    null,
    null,
  ])

  const setGlobalActions = useCallback((onAccept: onAcceptFn, onReject: onRejectFn) => {
    setActions([onAccept, onReject])
  }, [])

  return {
    globalOnAccept,
    globalOnReject,
    setGlobalActions,
  }
}
