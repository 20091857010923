import React, { FC } from 'react'

import { Styled } from './styled'

import { useToast } from 'components/organisms/Toast/hooks'
import { Button } from 'components/atoms/Button'
import { buttonColorTypes, buttonColors } from 'components/atoms/Button/config'

export interface ToastActionsProps {
  toastId: string
  onAccept: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onReject: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const ToastActions: FC<ToastActionsProps> = ({ toastId, onAccept, onReject }) => {
  const { removeToast } = useToast()

  const handleAccept = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onAccept) {
      onAccept(e)
      removeToast(toastId)
    }
  }

  const handleReject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onReject) {
      onReject(e)
      removeToast(toastId)
    }
  }

  return (
    <Styled.Wrapper>
      <Button
        colorType={buttonColorTypes.dark}
        variant={buttonColors.ghost}
        size="md"
        onClick={handleAccept}
      >
        Принять
      </Button>
      <Button
        colorType={buttonColorTypes.dark}
        variant={buttonColors.ghost}
        size="md"
        onClick={handleReject}
      >
        Отклонить
      </Button>
    </Styled.Wrapper>
  )
}

ToastActions.defaultProps = {
  onAccept: () => {},
  onReject: () => {},
}
