import { buttonStates, buttonColors, buttonColorsConfig, buttonPaddings, textSizes } from './config'

import { theme } from 'theme'
import { WrapperProps } from 'components/atoms/Button/types'

const getColors = (
  {
    disabled,
    isLoading,
    isDone,
    variant,
    colorType,
  }: Pick<WrapperProps, 'disabled' | 'isLoading' | 'isDone' | 'variant' | 'colorType'>,
  state: typeof buttonStates.default,
): {
  background: string
  text: string
  border: string
} => {
  const colorTypes = buttonColorsConfig[colorType]

  if (disabled) return colorTypes[buttonColors.disabled]
  if (isLoading) return colorTypes[buttonColors.loading]
  if (isDone) return colorTypes[buttonColors.done]
  if (state === buttonStates.focus) return colorTypes[buttonColors.focus]
  if (state === buttonStates.hover) return colorTypes[buttonColors.hover]
  if (state === buttonStates.active) return colorTypes[buttonColors.active]

  return colorTypes[variant]
}

export const shadowFocusBorder = `0 0 0 4px ${theme.colors.neutral.translucent}`

export const getButtonBorderColor = (props: WrapperProps, state = buttonStates.default): string =>
  getColors(props, state).border || theme.colors.transparent

export const getButtonBackgroundColor = (props: WrapperProps, state = buttonStates.default) =>
  getColors(props, state).background

export const getButtonTextColor = (props: WrapperProps, state = buttonStates.default) =>
  getColors(props, state).text

export const getButtonPadding = (props: {
  size: keyof typeof textSizes
  hasStartIcon: boolean
  hasEndIcon: boolean
  hasText: boolean
}) => {
  const { size, hasStartIcon, hasEndIcon, hasText } = props
  if (!hasText) return '0'
  if (hasStartIcon) return buttonPaddings.withStartIcon[size]
  if (hasEndIcon) return buttonPaddings.withEndIcon[size]

  return buttonPaddings.onlyText[size]
}

export const getBorderRadius = ({ size, rounded }: WrapperProps) =>
  `${rounded ? theme.radiuses.round : size === 'xl' ? theme.radiuses.xl : theme.radiuses.lg}px`

export const isButtonActive = ({
  disabled,
  isLoading,
  isDone,
}: Pick<WrapperProps, 'disabled' | 'isLoading' | 'isDone'>) => !disabled && !isLoading && !isDone
