import { Ref, RefCallback } from 'react'
import MaskedInput from 'react-text-mask'

export const isFunc = (value: unknown) => typeof value === 'function'

export const setReference = (componentRef: Ref<HTMLElement>, setRef: (tag: HTMLElement) => void) => (
  inputRef: MaskedInput,
) => {
  if (!inputRef) return
  // TODO: isFunc сделано для AutoComplete, выпилить проверку, когда заменим react-autosuggest
  const setNewRef = isFunc(componentRef) ? componentRef as RefCallback<HTMLElement> : setRef
  setNewRef(inputRef.inputElement)
}
