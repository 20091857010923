import React, { createContext, ReactNode, ReactNodeArray } from 'react'

import { useMediaQuery } from 'hooks/useMediaQuery'
import { theme } from 'theme'

const {
  breakpoints: { tablet, sm, md, lg },
} = theme

export const MediaBreakpointDefaultContextObj = {
  isMobile: false,
  isTablet: false,
  isSm: false,
  isMd: false,
  isLg: false,
}

export const MediaBreakpointContext = createContext(MediaBreakpointDefaultContextObj)

export const MediaBreakpointProvider = ({ children }: { children: ReactNode | ReactNodeArray }) => {
  const breakpoints = {
    isMobile: useMediaQuery(`(max-width:${tablet - 1}px)`),
    isTablet: useMediaQuery(`(min-width:${tablet}px) and (max-width:${sm - 1}px)`),
    isSm: useMediaQuery(`(min-width:${sm}px) and (max-width:${md - 1}px)`),
    isMd: useMediaQuery(`(min-width:${md}px) and (max-width:${lg - 1}px)`),
    isLg: useMediaQuery(`(min-width:${lg}px)`),
  }

  return (
    <MediaBreakpointContext.Provider value={breakpoints}>
      {children}
    </MediaBreakpointContext.Provider>
  )
}
