import React, { FC } from 'react'
import { createPortal } from 'react-dom'

import { Styled } from './styled'

import { ToastStorage } from 'components/organisms/Toast/types'
import { ToastItem } from 'components/organisms/Toast/molecules/ToastItem'
import { isBrowser } from 'utils'

const toastRoot = isBrowser && document.getElementById('toast')

export interface ToastContentProps {
  toastStorage: ToastStorage
  className?: string
}

export const ToastContent: FC<ToastContentProps> = ({ toastStorage, className }) => {
  const toastIds = Object.keys(toastStorage)

  return toastRoot && toastIds.length > 0
    ? createPortal(
        <Styled.Wrapper className={className}>
          {toastIds.map(id => (
            <ToastItem key={id} id={id} toast={toastStorage[id]} />
          ))}
        </Styled.Wrapper>,
        toastRoot,
      )
    : null
}