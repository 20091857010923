import React, { FC, HTMLAttributes, Ref, ReactNode } from 'react'

import { Styled } from './styled'

import { Text } from 'components/atoms/Text'
import { theme } from 'theme'
import { useBreakpoint } from 'hooks/index'

export interface InputControlProps extends HTMLAttributes<HTMLDivElement> {
  compRef?: Ref<HTMLDivElement>
  value?: string | number
  clearThreshold?: number
  isClearable?: boolean
  onClear?: (event: React.MouseEvent<Element | HTMLOrSVGElement, MouseEvent>) => void
  isOpen?: boolean
  isVisible?: boolean
  hasDropdown?: boolean
  hasEndIcon?: boolean
  icon?: string
  asPassword?: boolean
  onPasswordShow?: (event: React.MouseEvent<Element | HTMLOrSVGElement, MouseEvent>) => void
  tooltip?: string | ReactNode
  size?: 'xl' | 'lg' | 'md'
  disabled?: boolean
  readOnly?: boolean
  variant?: 'default' | 'privateBanking' | 'premiumBanking' | 'msbAccount'
}

export const InputControl: FC<Partial<InputControlProps>> = ({
  compRef,
  value,
  clearThreshold,
  isClearable,
  asPassword,
  hasDropdown,
  hasEndIcon,
  onClear,
  onPasswordShow,
  isOpen,
  isVisible,
  size,
  tooltip,
  icon,
  disabled,
  readOnly,
  children,
  variant,
  ...rest
}) => {
  const { isMobile } = useBreakpoint()

  const isInputClearable = isClearable && String(value).length > clearThreshold
  const isClearIconHidden = !isClearable || String(value).length <= clearThreshold
  const tooltipText = isVisible ? 'Скрыть пароль' : 'Показать пароль'
  const iconPassword = isVisible ? 'baseX24/ic-visibility-on' : 'baseX24/ic-visibility-off'
  const withTooltip = (isMobile || size === 'xl') && tooltip && !asPassword
  const isAllIconsHidden =
    !hasDropdown && isClearIconHidden && !withTooltip && !asPassword && !children

  const isClearIconMargined = hasDropdown || asPassword || withTooltip || hasEndIcon || !!children

  return (
    <Styled.Wrapper
      hasEndIcon={hasEndIcon}
      icon={!!icon}
      hasAdditionalItem={asPassword || !!children}
      tooltip={tooltip}
      ref={compRef}
      size={size}
      isClearIconHidden={isClearIconHidden}
      isAllIconsHidden={isAllIconsHidden}
      hasDropdown={hasDropdown}
      isMobile={isMobile}
      {...rest}
    >
      {isInputClearable && !disabled && !readOnly && (
        <Styled.ClearIcon
          variant={variant}
          isIconSecond={isClearIconMargined}
          withWrapper
          icon={'baseX24/ic-close-1'}
          onClick={onClear}
        />
      )}
      {hasDropdown && (
        <Styled.RotatingCustomIcon
          withWrapper
          variant={variant}
          icon={'baseX24/ic-arrow-down'}
          isOpen={!disabled && isOpen}
        />
      )}
      {asPassword && !disabled ? (
        <Styled.CustomTippy
          interactive
          arrow
          maxWidth="288px"
          placement="bottom"
          content={
            <Text size="md" color={theme.colors.white}>
              {tooltipText}
            </Text>
          }
        >
          <Styled.CustomIcon
            withWrapper
            icon={iconPassword}
            onClick={onPasswordShow}
            variant={variant}
          />
        </Styled.CustomTippy>
      ) : (
        asPassword && (
          <Styled.CustomIcon disabled={disabled} icon={iconPassword} variant={variant} />
        )
      )}
      {withTooltip && (
        <Styled.CustomToolTip placement={isMobile ? 'bottom' : 'top'} hint={tooltip} onClick={e => e.stopPropagation()} />
      )}
      {children}
    </Styled.Wrapper>
  )
}

InputControl.defaultProps = {
  compRef: null,
  value: '',
  clearThreshold: 0,
  isClearable: false,
  onClear: null,
  isOpen: false,
  isVisible: true,
  hasDropdown: false,
  size: 'lg',
  asPassword: false,
  onPasswordShow: null,
  tooltip: '',
}
